
<template>
    <div :class="{over: ddOver}"
         v-cloak
         @drop.prevent="e => ddUploadConfirmation(e)"
         @dragover.prevent
         @dragenter="over(true)"
         @dragleave="over(false)"
         @dragover="over(true)"
         @click="activeSelect = false"
    >

        <div class="fullscreen-page-title">
            {{ tokens.fullScreen.deposit.toUpperCase() }}
        </div>
        <div class="fullscreen-page-sub-title">
            METHODS
        </div>
        <div v-if="user.verified === 'NO' && onlyBTCpsp" class="deposit">
            <div class="methods">
                <div class="item"
                     :class="{active: tabActive === 'nax', hide: !pspList.filter(data => data.name !== undefined && data.name === 'nax').length}"
                     @click="tabActive = 'nax'"
                >
                    <div class="img"><i class="material-icons">cloud</i></div>
                    <div class="name">
                        <span>Crypto</span>
                        <span>{{ pspAdditionalText() }}</span>
                    </div>
                </div>
            </div>

                <div class="address" :class="{hide: tabActive !== 'nax'}">
                <div class="form-title">CHOOSE CRYPTOCURRENCY</div>
                <div class="crypto-icons">
                    <button class="item fs" :class="{active: currency === 'BTC'}" @click="changePsp('nax', 'BTC')">
                        Bitcoin
                    </button>
                </div>
                <div class="address-generated" v-show="!address">
                    {{ tokens.fullScreenDeposit.addressReceipt }}
                </div>

                <div class="address-content" v-show="address">
                    <div class="form-row qrcode">
                        <div class="form-title">{{ tokens.fullScreenDeposit.qrCode }}</div>
                        <div class="qr-new">
                            <QrcodeVue :value="address" :size="150" />
                        </div>
                    </div>
                    <div class="form-row nopad">
                        <div class="form-title">{{ tokens.fullScreenDeposit.depositAddress }}</div>
                    </div>
                    <div class="qr-copy">
                        <input readonly v-model="address" class="form-control fs" />
                        <button class="copy fs" @click="copyDepositAddress">{{ cryptoDepositState === 'copy' ? tokens.fullScreenDeposit.copy : tokens.fullScreenDeposit.continue }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="deposit" :class="{'no-psp':hasNotAvailablePsp}">
                <div class="methods">

                    <!-- first item cructh for virtualwealthexchange -->

                    <div class="item"
                        v-if="hasSolidpaymentPsp && ['stage_sharksoft','virtualwealthexchange'].includes(brand)"
                        :class="{active: tabActive === 'solidpayment',
                        hide:  !pspList.filter(data => data.name !== undefined && data.name === 'solidpayment').length}"

                        @click="tabActive = 'solidpayment'"
                    >
                        <div class="img"><i class="material-icons">credit_card</i></div>
                        <div class="name">
                            <span>{{ pspConfig['solidpayment'].name }}</span>
                            <span>{{ pspAdditionalText() }}</span>
                        </div>
                    </div>

                    <div class="item"
                        :class="{active: tabActive === 'nax', hide: !pspList.filter(data => data.name !== undefined && data.name === 'nax').length}"
                        @click="tabActive = 'nax'"
                    >
                        <div class="img"><i class="material-icons">cloud</i></div>
                        <div class="name">
                            <span>Crypto</span>
                            <span>{{ pspAdditionalText() }}</span>
                        </div>
                    </div>

                    <div class="item"
                        :class="{active: tabActive === 'bitcoin_manual', hide:  !pspList.filter(data => data.name !== undefined && data.name === 'bitcoin_manual').length}"
                        @click="tabActive = 'bitcoin_manual'"
                    >
                        <div class="img"><i class="material-icons">cloud</i></div>
                        <div class="name">
                            <span>Bitcoin Wallet</span>
                            <span>{{ pspAdditionalText() }}</span>
                        </div>
                    </div>

                    <div class="item"
                        :class="{active: tabActive === 'ethereum_manual', hide: !pspList.filter(data => data.name !== undefined && data.name === 'ethereum_manual').length}"
                        @click="tabActive = 'ethereum_manual'"
                    >
                        <div class="img"><i class="material-icons">cloud</i></div>
                        <div class="name">
                            <span>Ethereum Wallet</span>
                            <span>{{ pspAdditionalText() }}</span>
                        </div>
                    </div>
                    <div class="item"
                        :class="{active: tabActive === 'xrp_manual', hide: !pspList.filter(data => data.name !== undefined && data.name === 'xrp_manual').length}"
                        @click="tabActive = 'xrp_manual'"
                    >
                        <div class="img"><i class="material-icons">cloud</i></div>
                        <div class="name">
                            <span>XRP Wallet</span>
                            <span>{{ pspAdditionalText() }}</span>
                        </div>
                    </div>

                    <div class="item"
                        :class="{active: tabActive === 'usdt_erc20_manual', hide: !pspList.filter(data => data.name !== undefined && data.name === 'usdt_erc20_manual').length}"
                        @click="tabActive = 'usdt_erc20_manual'"
                    >
                        <div class="img"><i class="material-icons">cloud</i></div>
                        <div class="name">
                            <span>USDT ERC20 Wallet</span>
                            <span>{{ pspAdditionalText() }}</span>
                        </div>
                    </div>

                    <div class="item"
                        :class="{active: tabActive === 'usdt_trc20_manual', hide: !pspList.filter(data => data.name !== undefined && data.name === 'usdt_trc20_manual').length}"
                        @click="tabActive = 'usdt_trc20_manual'"
                    >
                        <div class="img"><i class="material-icons">cloud</i></div>
                        <div class="name">
                            <span>USDT TRC20 Wallet</span>
                            <span>{{ pspAdditionalText() }}</span>
                        </div>
                    </div>

                    <div class="item"
                        :class="{active: tabActive === 'usdc_trc20_manual', hide: !pspList.filter(data => data.name !== undefined && data.name === 'usdc_trc20_manual').length}"
                        @click="tabActive = 'usdc_trc20_manual'"
                    >
                        <div class="img"><i class="material-icons">cloud</i></div>
                        <div class="name">
                            <span>USDC TRC20 Wallet</span>
                            <span>{{ pspAdditionalText() }}</span>
                        </div>
                    </div>

                    <div class="item"
                        :class="{active: tabActive === 'usdc_erc20_manual', hide: !pspList.filter(data => data.name !== undefined && data.name === 'usdc_erc20_manual').length}"
                        @click="tabActive = 'usdc_erc20_manual'"
                    >
                        <div class="img"><i class="material-icons">cloud</i></div>
                        <div class="name">
                            <span>USDC ERC20 Wallet</span>
                            <span>{{ pspAdditionalText() }}</span>
                        </div>
                    </div>

                    <div class="item"
                        :class="{active: tabActive === 'cc-ext', hide: !pspList.filter(data => data.name !== undefined && data.name === 'cc-ext').length}"
                        @click="tabActive = 'cc-ext'"
                    >
                        <div class="img"><i class="material-icons">credit_card</i></div>
                        <div class="name">
                            <span>Credit/Debit Card {{ !pspList.filter(data => data.name !== undefined && data.name === 'cc-ext').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'cc-ext')[0].name }}</span>
                            <span>{{ pspAdditionalText() }}</span>
                        </div>
                    </div>

                    <div v-if="!['adlerch'].includes(brand)"
                        class="item"
                        :class="{active: tabActive === 'cc-ext2', hide: !pspList.filter(data => data.name !== undefined && data.name === 'cc-ext2').length}"
                        @click="tabActive = 'cc-ext2'"
                    >
                        <div class="img"><i class="material-icons">credit_card</i></div>
                        <div class="name">
                            <span>Credit/Debit Card {{ !pspList.filter(data => data.name !== undefined && data.name === 'cc-ext2').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'cc-ext2')[0].name }}</span>
                            <span>{{ pspAdditionalText() }}</span>
                        </div>
                    </div>

                    <div class="item"
                        :class="{active: tabActive === 'sofort', hide: !sofortCustomPsp}"
                        @click="tabActive = 'sofort'"
                    >
                        <div class="img"><i class="material-icons">credit_card</i></div>
                        <div class="name">
                            <span>Sofort</span>
                            <span>{{ pspAdditionalText() }}</span>
                        </div>
                    </div>

                    <div v-if="!['adlerch'].includes(brand)"
                        class="item"
                        :class="{active: tabActive === 'bank', hide: !pspList.filter(data => data.name !== undefined && data.name === 'bank').length}"
                        @click="tabActive = 'bank'">
                        <div class="img">
                            <i class="material-icons">account_balance</i>
                        </div>
                        <div class="name">
                            <span>Bank Transfer</span>
                            <span>{{ pspAdditionalText('days') }}</span>
                        </div>
                    </div>

                    <div class="item"
                        :class="{active: tabActive === 'alwayspay_visa', hide: !pspList.filter(data => data.name !== undefined && data.name === 'alwayspay_visa').length}"
                        @click="tabActive = 'alwayspay_visa'"
                    >
                        <div class="img"><i class="material-icons">credit_card</i></div>
                        <div class="name">
                            <span>Credit/Debit Card / APV</span>
                            <span>{{ pspAdditionalText() }}</span>
                        </div>
                    </div>

                    <div class="item"
                        :class="{active: tabActive === 'alwayspay_master', hide: !pspList.filter(data => data.name !== undefined && data.name === 'alwayspay_master').length}"
                        @click="tabActive = 'alwayspay_master'"
                    >
                        <div class="img"><i class="material-icons">credit_card</i></div>
                        <div class="name">
                            <span>Credit/Debit Card / APM</span>
                            <span>{{ pspAdditionalText() }}</span>
                        </div>
                    </div>

                    <div class="item"
                        :class="{active: tabActive === 'chargemoney', hide: !pspList.filter(data => data.name !== undefined && data.name === 'chargemoney').length}"
                        @click="tabActive = 'chargemoney'"
                    >
                        <div class="img"><i class="material-icons">credit_card</i></div>
                        <div class="name">
                            <span>Credit/Debit Card/CM+CP</span>
                            <span>{{ pspAdditionalText() }}</span>
                        </div>
                    </div>

                    <!-- styles for solidpayment crutch for virtualwealthexchange -->

                    <div class="item"
                            v-for="(config, name) in pspConfig"
                            :key="name"
                            :class="{active: tabActive === name, hide: !pspList.filter(data => data.name !== undefined && data.name === name).length}"
                            :style="name === 'solidpayment' && ['stage_sharksoft','virtualwealthexchange'].includes(brand) ? 'display : none' : ''"
                            @click="tabActive = name"
                    >
                        <div class="img"><i class="material-icons">credit_card</i></div>
                        <div class="name">
                            <span>{{ config.name }}</span>
                            <span>{{ pspAdditionalText() }}</span>
                        </div>
                    </div>


                    <div class="item"
                        v-for="(config, name) in pspConfigCountries"
                        :key="name"
                        :class="{active: tabActive === name, hide: !pspList.filter(data => data.name !== undefined && data.name === name).length}"
                        @click="tabActive = name"
                    >
                        <div class="img"><i class="material-icons">credit_card</i></div>
                        <div class="name">
                            <span>{{ config.name }}</span>
                            <span>{{ pspAdditionalText() }}</span>
                        </div>
                    </div>

                    <div class="item"
                        v-show="otherPsps.length > 0"
                        :class="{active: tabActive === 'other'}"
                        @click="tabActive = 'other'"
                    >
                        <div class="img">
                            <i class="material-icons">apps</i>
                        </div>
                        <div class="name">
                            <span v-if="otherPspTabName" >{{ otherPspTabName }}</span>
                            <span v-else>Other</span>
                            <span>{{ pspAdditionalText() }}</span>
                        </div>
                    </div>
                </div>

                <div class="amounts" :class="{hide: tabActive !== 'bank'}">
                    <div v-if="!hasPendingBankDeposit">
                        <div class="form-title copy" v-show="variables.BT_ACCOUNT">{{ tokens.depositModal['BT_TITLE'].toUpperCase() }}
                            <i class="material-icons" @click="copyObject(variables)">file_copy</i>
                        </div>
                        <div class="banking-details">
                            <div v-for="(variable, i) in variables" :key="i" v-show="variable">
                                {{ tokens.depositModal[i] }}<span class="value" v-if="i"> : {{ variable }}</span>
                            </div>
                        </div>
                        <div class="form-title">{{ tokens.depositModal['BT_INVOICE'].toUpperCase() }}</div>
                        <div class="amount" v-if="!hasPendingBankDeposit">{{ tokens.fullScreenDeposit.amount }}</div>
                        <div class="row-fs" style="justify-content: flex-start" v-if="!hasPendingBankDeposit">
                            <div class="cont">
                                <input v-model="amount" />
                                <select class="form-control bank-currency fs" v-model="bankCurrencyChosen" :class="{activeSelect: activeSelect}" @click.stop="activeSelect = !activeSelect">
                                    <option v-for="(bankCurrency, i) in bankCurrencies" :key="i">{{ bankCurrency }}</option>
                                </select>
                            </div>
                            <button class="fs-normal proceed" @click="generateInvoice()">Generate Invoice</button>
                        </div>
                    </div>


                    <div class="has-bank-transfer" v-if="hasPendingBankDeposit">
                        <div class="form-title">You have a pending bank transfer deposit. Please do the following:</div>
                        <ul class="bank-steps">
                            <li>
                                <h2><span>1</span> Download the invoice</h2>
                                <div class="center"><button class="fs-normal" @click="downloadPdf(bankDeposit.id)">Download PDF</button></div>
                            </li>
                            <li>
                                <h2><span>2</span> Pay the invoice in your bank</h2>
                            </li>
                            <li v-show="!bankDeposit.misc">
                                <h2><span>3</span> Upload the confirmation receipt</h2>
                                <div class="center">
                                    <label for="file" class="attach">Select File</label>
                                    <input type="file" id="file" @change="uploadConfirmation($event)">
                                </div>
                                <div class="center">or drag and drop file onto this page</div>
                            </li>
                            <li v-show="bankDeposit.misc">
                                <h2><span>3</span> The receipt has been uploaded</h2>
                            </li>
                            <li>
                                <h2><span>4</span> Wait for deposit confirmation</h2>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="amounts" :class="{hide: tabActive !== 'cc-ext'}">
                    <div class="col">
                        <div class="card-container">
                            <img v-if="['astroglobal365','byronplusglobal'].includes(this.brand)" class="card-img" src="@/assets/image/credit-card-all.png" />
                            <img v-else-if="['mercuryfintech','goldencoastvc'].includes(this.brand)"
                            class="card-img" src="@/assets/image/credit-card-visa-master.png" />
                            <img v-else class="card-img" src="@/assets/image/credit-card.png" />

                            <input class="card-input input-fs-1" v-model="cardData.num1" type="text" pattern="\d*" maxlength="4" placeholder="0000" @keyup="cardKeyup($event, 'num1')" ref="card-num1" />
                            <input class="card-input input-fs-2" v-model="cardData.num2"  type="text" pattern="\d*" maxlength="4" placeholder="0000" @keyup="cardKeyup($event, 'num2')" ref="card-num2" />
                            <input class="card-input input-fs-3" v-model="cardData.num3" type="text" pattern="\d*" maxlength="4" placeholder="0000" @keyup="cardKeyup($event, 'num3')" ref="card-num3" />
                            <input class="card-input input-fs-4" v-model="cardData.num4"  type="text" pattern="\d*" maxlength="4" placeholder="0000" @keyup="cardKeyup($event, 'num4')" ref="card-num4" />
                            <input class="card-input input-fs-5" v-model="cardData.month"  type="text" pattern="\d*" maxlength="2" placeholder="MM" @keyup="cardKeyup($event, 'month')" ref="card-month" />
                            <input class="card-input input-fs-6" v-model="cardData.year" type="text" pattern="\d*" maxlength="2" placeholder="YY" @keyup="cardKeyup($event, 'year')" ref="card-year" />
                            <input class="card-input input-fs-7" v-model="cardData.cvv"  type="text" pattern="\d*" maxlength="3" placeholder="123" @keyup="cardKeyup($event, 'cvv')" ref="card-cvv" />
                            <select class="card-input form-control input-fs-8" v-$model="currency" :class="{activeSelect: activeSelect}" @click.stop="activeSelect = !activeSelect">
                                <option>USD</option>
                                <option>EUR</option>
                                <option>GBP</option>
                                <option>RUB</option>
                                <option>CAD</option>
                                <option>PLN</option>
                                <option>KZT</option>
                            </select>
                        </div>
                        <div class="more-bank-info" v-if="brand === 'appletrade'">
                            <div class="box">
                                <div class="amount">{{ tokens.fullScreenDeposit.name }}</div>
                                <input v-model="moreCardDetails.name" class="form-control fs"/>
                            </div>
                            <div class="box">
                                <div class="amount">{{ tokens.fullScreenDeposit.address }}</div>
                                <input v-model="moreCardDetails.address" class="form-control fs"/>
                            </div>
                            <div class="box">
                                <div class="amount">{{ tokens.fullScreenDeposit.DOB }}</div>
                                <input v-model="moreCardDetails.DOB" class="form-control fs"/>
                            </div>
                            <div class="box">
                                <div class="amount">{{ tokens.fullScreenDeposit.bankName }}</div>
                                <input v-model="moreCardDetails.bankName" class="form-control fs"/>
                            </div>
                        </div>
                        <div class="more-bank-info" v-if="fakePspAdditionalFields">
                            <div class="box">
                                <div class="amount">{{ tokens.fullScreenDeposit.name }}</div>
                                <input v-model="moreCardDetails.name" class="form-control fs" @input="onlyTextInput($event)" name="name"/>
                            </div>
                            <div class="box">
                                <div class="amount">{{ 'Country' }}</div>
                                <select v-model="moreCardDetails.country" class="form-control fs">
                                    <option v-for="country in countriesList()" :key="country" :value="country">{{ country }}</option>
                                </select>
                            </div>
                            <div class="box">
                                <div class="amount">{{ 'City' }}</div>
                                <input v-model="moreCardDetails.city" class="form-control fs" @input="onlyTextInput($event)" name="city"/>
                            </div>
                            <div class="box">
                                <div class="amount">{{ tokens.fullScreenDeposit.address }}</div>
                                <input v-model="moreCardDetails.address" class="form-control fs"/>
                            </div>
                            <div class="box">
                                <div class="amount">{{ 'Postcode' }}</div>
                                <input v-model="moreCardDetails.postcode" class="form-control fs"/>
                            </div>
                        </div>
                        <div class="amount">{{ tokens.fullScreenDeposit.amount }}</div>
                        <div class="row-fs stretch">
                            <div class="cont">
                                <input v-model="amount" ref="card-amount" />
                                <div class="sign">{{ currencySign(currency) }}</div>
                            </div>
                            <button v-if="!hideDepositOption" class="fs-small proceed" @click="makeDeposit">{{ tokens.fullScreenDeposit.continue }}</button>
                        </div>
                    </div>
                </div>

                <div class="amounts" v-show="tabActive === 'alwayspay_visa'">
                    <div class="col">
                        <div class="card-container">
                            <img v-if="['astroglobal365','byronplusglobal'].includes(this.brand)" class="card-img" src="@/assets/image/credit-card-all.png" />
                            <img v-else-if="['mercuryfintech','goldencoastvc'].includes(this.brand)"
                            class="card-img" src="@/assets/image/credit-card-visa-master.png" />
                            <img v-else class="card-img" src="@/assets/image/credit-card.png" />

                            <input class="card-input input-fs-1" v-model="cardData.num1" type="text" pattern="\d*" maxlength="4" placeholder="0000" @keyup="cardKeyup($event, 'num1')" ref="card-num1" />
                            <input class="card-input input-fs-2" v-model="cardData.num2" type="text" pattern="\d*" maxlength="4" placeholder="0000" @keyup="cardKeyup($event, 'num2')" ref="card-num2" />
                            <input class="card-input input-fs-3" v-model="cardData.num3" type="text" pattern="\d*" maxlength="4" placeholder="0000" @keyup="cardKeyup($event, 'num3')" ref="card-num3" />
                            <input class="card-input input-fs-4" v-model="cardData.num4"  type="text" pattern="\d*" maxlength="4" placeholder="0000" @keyup="cardKeyup($event, 'num4')" ref="card-num4" />
                            <input class="card-input input-fs-5" v-model="cardData.month" type="text" pattern="\d*" maxlength="2" placeholder="MM" @keyup="cardKeyup($event, 'month')" ref="card-month" />
                            <input class="card-input input-fs-6" v-model="cardData.year"  type="text" pattern="\d*" maxlength="2" placeholder="YY" @keyup="cardKeyup($event, 'year')" ref="card-year" />
                            <input class="card-input input-fs-7" v-model="cardData.cvv"  type="text" pattern="\d*" maxlength="3" placeholder="123" @keyup="cardKeyup($event, 'cvv')" ref="card-cvv" />
                            <select class="card-input form-control input-fs-8" v-$model="currency" :class="{activeSelect: activeSelect}" @click.stop="activeSelect = !activeSelect">
                                <option>USD</option>
                            </select>
                        </div>
                        <div class="more-bank-info" v-if="brand === 'appletrade'">
                            <div class="box">
                                <div class="amount">{{ tokens.fullScreenDeposit.name }}</div>
                                <input v-model="moreCardDetails.name" class="form-control fs"/>
                            </div>
                            <div class="box">
                                <div class="amount">{{ tokens.fullScreenDeposit.address }}</div>
                                <input v-model="moreCardDetails.address" class="form-control fs"/>
                            </div>
                            <div class="box">
                                <div class="amount">{{ tokens.fullScreenDeposit.DOB }}</div>
                                <input v-model="moreCardDetails.DOB" class="form-control fs"/>
                            </div>
                            <div class="box">
                                <div class="amount">{{ tokens.fullScreenDeposit.bankName }}</div>
                                <input v-model="moreCardDetails.bankName" class="form-control fs"/>
                            </div>
                        </div>
                        <div class="amount">{{ tokens.fullScreenDeposit.amount }}</div>
                        <div class="row-fs stretch">
                            <div class="cont">
                                <input v-model="amount" ref="card-amount" />
                                <!--                            <div class="sign">{{ currencySign(currency) }}</div>-->
                            </div>
                            <button v-if="!hideDepositOption" class="fs-small proceed" @click="makeDeposit">{{ tokens.fullScreenDeposit.continue }}</button>
                        </div>
                    </div>
                </div>

                <div class="amounts" v-show="tabActive === 'alwayspay_master'">
                    <div class="col">
                        <div class="card-container">
                            <img class="card-img" src="@/assets/image/credit-card-master.png" />
                            <input class="card-input input-fs-1" v-model="cardData.num1" type="text" pattern="\d*" maxlength="4" placeholder="0000" @keyup="cardKeyupMaster($event, 'num1-m')" ref="card-num1-m" />
                            <input class="card-input input-fs-2" v-model="cardData.num2"  type="text" pattern="\d*" maxlength="4" placeholder="0000" @keyup="cardKeyupMaster($event, 'num2-m')" ref="card-num2-m" />
                            <input class="card-input input-fs-3" v-model="cardData.num3"  type="text" pattern="\d*" maxlength="4" placeholder="0000" @keyup="cardKeyupMaster($event, 'num3-m')" ref="card-num3-m" />
                            <input class="card-input input-fs-4" v-model="cardData.num4" type="text" pattern="\d*" maxlength="4" placeholder="0000" @keyup="cardKeyupMaster($event, 'num4-m')" ref="card-num4-m" />
                            <input class="card-input input-fs-5" v-model="cardData.month"  type="text" pattern="\d*" maxlength="2" placeholder="MM" @keyup="cardKeyupMaster($event, 'month-m')" ref="card-month-m" />
                            <input class="card-input input-fs-6" v-model="cardData.year" type="text" pattern="\d*" maxlength="2" placeholder="YY" @keyup="cardKeyupMaster($event, 'year-m')" ref="card-year-m" />
                            <input class="card-input input-fs-7" v-model="cardData.cvv" style="" type="text" pattern="\d*" maxlength="3" placeholder="123" @keyup="cardKeyupMaster($event, 'cvv-m')" ref="card-cvv-m" />
                            <select class="card-input form-control input-fs-8" v-$model="currency" style="" :class="{activeSelect: activeSelect}" @click.stop="activeSelect = !activeSelect">
                                <option>USD</option>
                            </select>
                        </div>
                        <div class="more-bank-info" v-if="brand === 'appletrade'">
                            <div class="box">
                                <div class="amount">{{ tokens.fullScreenDeposit.name }}</div>
                                <input v-model="moreCardDetails.name" class="form-control fs"/>
                            </div>
                            <div class="box">
                                <div class="amount">{{ tokens.fullScreenDeposit.address }}</div>
                                <input v-model="moreCardDetails.address" class="form-control fs"/>
                            </div>
                            <div class="box">
                                <div class="amount">{{ tokens.fullScreenDeposit.DOB }}</div>
                                <input v-model="moreCardDetails.DOB" class="form-control fs"/>
                            </div>
                            <div class="box">
                                <div class="amount">{{ tokens.fullScreenDeposit.bankName }}</div>
                                <input v-model="moreCardDetails.bankName" class="form-control fs"/>
                            </div>
                        </div>
                        <div class="amount">{{ tokens.fullScreenDeposit.amount }}</div>
                        <div class="row-fs stretch">
                            <div class="cont">
                                <input v-model="amount" ref="card-amount" />
                                <!--                            <div class="sign">{{ currencySign(currency) }}</div>-->
                            </div>
                            <button v-if="!hideDepositOption" class="fs-small proceed" @click="makeDeposit">{{ tokens.fullScreenDeposit.continue }}</button>
                        </div>
                    </div>
                </div>
                <div class="amounts" v-show="tabActive === 'chargemoney'">
                    <div class="col">
                        <div class="card-container">
                            <img class="card-img" src="@/assets/image/credit-card-master.png" />
                            <input class="card-input input-fs-1" v-model="cardData.num1" type="text" pattern="\d*" maxlength="4" placeholder="0000" @keyup="cardKeyupMaster($event, 'num1-m')" ref="card-num1-m" />
                            <input class="card-input input-fs-2" v-model="cardData.num2"  type="text" pattern="\d*" maxlength="4" placeholder="0000" @keyup="cardKeyupMaster($event, 'num2-m')" ref="card-num2-m" />
                            <input class="card-input input-fs-3" v-model="cardData.num3"  type="text" pattern="\d*" maxlength="4" placeholder="0000" @keyup="cardKeyupMaster($event, 'num3-m')" ref="card-num3-m" />
                            <input class="card-input input-fs-4" v-model="cardData.num4" type="text" pattern="\d*" maxlength="4" placeholder="0000" @keyup="cardKeyupMaster($event, 'num4-m')" ref="card-num4-m" />
                            <input class="card-input input-fs-5" v-model="cardData.month"  type="text" pattern="\d*" maxlength="2" placeholder="MM" @keyup="cardKeyupMaster($event, 'month-m')" ref="card-month-m" />
                            <input class="card-input input-fs-6" v-model="cardData.year" type="text" pattern="\d*" maxlength="2" placeholder="YY" @keyup="cardKeyupMaster($event, 'year-m')" ref="card-year-m" />
                            <input class="card-input input-fs-7" v-model="cardData.cvv" style="" type="text" pattern="\d*" maxlength="3" placeholder="123" @keyup="cardKeyupMaster($event, 'cvv-m')" ref="card-cvv-m" />
                            <select class="card-input form-control input-fs-8" v-$model="currency" style="" :class="{activeSelect: activeSelect}" @click.stop="activeSelect = !activeSelect">
                                <option>USD</option>
                                <option>CAD</option>
                            </select>
                        </div>
                        <div class="amount">{{ tokens.fullScreenDeposit.amount }}</div>
                        <div class="row-fs stretch">
                            <div class="cont">
                                <input v-model="amount" ref="card-amount" />
                                <!--                            <div class="sign">{{ currencySign(currency) }}</div>-->
                            </div>
                            <button v-if="!hideDepositOption" class="fs-small proceed" @click="makeDeposit">{{ tokens.fullScreenDeposit.continue }}</button>
                        </div>
                    </div>
                </div>
                <div class="amounts" :class="{hide: tabActive !== 'cc-ext2'}">
                    <div class="col">
                        <a class="goBackToAmount" @click="goBackToAmount" v-if="showPSP" >Back to amount</a>
                        <div class="amount">{{ tokens.fullScreenDeposit.amount }}</div>
                        <div class="row-fs stretch" v-if="showAmount">
                            <div class="cont">
                                <input v-model="amount" ref="card-amount" />
                                <div class="sign">{{ currencySign(currency) }}</div>
                            </div>
                            <button v-if="!hideDepositOption" class="fs-small proceed" @click="makeDeposit">{{ tokens.fullScreenDeposit.continue }}</button>
                        </div>
                        <CCExt2 :amount="amount" v-if="showPSP" />
                    </div>
                </div>

                <div class="amounts" v-for="(config, name) in getPspConfig"
                    :key="name"
                    :class="{hide: tabActive !== name}">
                    <div class="row-fs buttons-row" v-show="!isMobile">
                        <div class="col">
                            <div class="item"
                                v-for="button in depositButtonsList.left"
                                :key="'button'+button.amount"
                                @click="amount = button.amount">{{ button.amountStr }}</div>
                        </div>
                        <div class="col">
                            <div class="item"
                                v-for="button in depositButtonsList.right"
                                :key="'button'+button.amount"
                                @click="amount = button.amount">{{ button.amountStr }}</div>
                        </div>
                    </div>
                    <div class="amount" v-if="!(tabActive === 'cricpayz' && upiGoQrCode)">{{ tokens.fullScreenDeposit.amount }}</div>
                    <div class="row-fs" v-if="!(tabActive === 'cricpayz' && upiGoQrCode)">
                        <div class="cont">
                            <input v-model="amount" />
                            <select class="currency fs" v-$model="currency" :class="{activeSelect: activeSelect}" @click.stop="activeSelect = !activeSelect">
                                <option
                                    v-for="currency in config.currencies"
                                    :key="currency"
                                >{{ currency }}</option>
                            </select>
                        </div>
                        <button v-if="!hideDepositOption" class="fs-normal proceed" @click="makeDeposit">{{ tokens.fullScreenDeposit.continue }}</button>
                    </div>
                    <div class="upi-go-qrcode" v-if="tabActive === 'cricpayz' && upiGoQrCode">
                        <img :src="`img/UPIGO-${brand}.png`" alt="UPI Go">
                    </div>
                </div>

                <div class="amounts" v-for="(config, name) in pspConfigCountries"
                    :key="name"
                    :class="{hide: tabActive !== name}">
                    <div class="countries-psp">
                        <button  class="item fs" :class="{active: activeCountry === i}" @click="changePspCountry(name, i)" v-for="(country, i) in pspConfigCountries[name].countries" :key="i">
                            {{country.name}}
                        </button>
                    </div>
                    <label v-if="name === '4on' && activeCountry">Method:</label>
                    <select v-if="name === '4on' && activeCountry" class="methods fs" v-$model="activeMethod" :class="{activeSelect: activeSelect}" @click.stop="activeSelect = !activeSelect">
                        <option
                            v-for="(method, i) in config.countries[activeCountry]?.['methods']"
                            :value="i"
                            :key="i"
                        >{{ method }}</option>
                    </select>
                    <div class="row-fs buttons-row" v-show="!isMobile" v-if="(name === '4on' && activeMethod) && activeCountry">
                        <div class="col">
                            <div class="item"
                                v-for="button in depositButtonsList.left"
                                :key="'button'+button.amount"
                                @click="amount = button.amount">{{ button.amountStr }}</div>
                        </div>
                        <div class="col">
                            <div class="item"
                                v-for="button in depositButtonsList.right"
                                :key="'button'+button.amount"
                                @click="amount = button.amount">{{ button.amountStr }}</div>
                        </div>
                    </div>
                    <div class="amount"  v-if="activeCountry">{{ tokens.fullScreenDeposit.amount }}</div>
                    <div class="row-fs" v-if="activeCountry">
                        <div class="cont">
                            <input v-model="amount" />
                            <select class="currency fs" v-$model="currency" :class="{activeSelect: activeSelect}" @click.stop="activeSelect = !activeSelect">
                                <option
                                    v-for="currency in config.countries[activeCountry]?.['currencies']"
                                    :key="currency"
                                >{{ currency }}</option>
                            </select>
                        </div>
                        <button v-if="!hideDepositOption" class="fs-normal proceed" @click="makeDeposit">{{ tokens.fullScreenDeposit.continue }}</button>
                    </div>
                </div>

                <div class="address" :class="{hide: tabActive !== 'nax'}">
                    <div class="form-title">CHOOSE CRYPTOCURRENCY</div>
                    <div class="crypto-icons">
                        <button class="item fs" :class="{active: currency === 'BTC'}" @click="changePsp('nax', 'BTC')">
                            Bitcoin
                        </button>
                    </div>
                    <div class="address-generated" v-show="!address">
                        {{ tokens.fullScreenDeposit.addressReceipt }}
                    </div>

                    <div class="address-content" v-show="address">
                        <div class="form-row qrcode">
                            <div class="form-title">{{ tokens.fullScreenDeposit.qrCode }}</div>
                            <div class="qr-new">
                                <QrcodeVue :value="address" :size="150" />
                            </div>
                        </div>
                        <div class="form-row nopad">
                            <div class="form-title">{{ tokens.fullScreenDeposit.depositAddress }}</div>
                        </div>
                        <div class="qr-copy">
                            <input readonly v-model="address" class="form-control fs" />
                            <button class="copy fs" @click="copyDepositAddress">{{ cryptoDepositState === 'copy' ? tokens.fullScreenDeposit.copy : tokens.fullScreenDeposit.continue }}</button>
                        </div>
                    </div>
                </div>

                <div class="other" :class="{hide: tabActive !== 'other'}">
                    <a target="_blank"
                    v-for="(otherPsp, index) in otherPsps"
                    :key="index"
                    :href="otherPsp.url"
                    >
                        <img :src="`${staticBase}exchanges/custom/${otherPsp.name}.png`">

                    </a>
                </div>

                <div class="address" :class="{hide: tabActive !== 'bitcoin_manual'}">
                    <div class="address-generated" v-show="!pspList.filter(data => data.name !== undefined && data.name === 'bitcoin_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'bitcoin_manual')[0].wallet === ''">
                        {{ tokens.fullScreenDeposit.addWalletAddress }}
                    </div>

                    <div class="address-content" v-show="!pspList.filter(data => data.name !== undefined && data.name === 'bitcoin_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'bitcoin_manual')[0].wallet !== ''">
                        <div class="form-row qrcode">
                            <div class="form-title">{{ tokens.fullScreenDeposit.qrCode }}</div>
                            <div class="qr-new">
                                <QrcodeVue :value="!pspList.filter(data => data.name !== undefined && data.name === 'bitcoin_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'bitcoin_manual')[0].wallet" :size="150" />
                            </div>
                        </div>
                        <div class="form-row nopad">
                            <div class="form-title">{{ tokens.fullScreenDeposit.depositAddress }}</div>
                        </div>
                        <div class="qr-copy">
                            <input readonly :value="!pspList.filter(data => data.name !== undefined && data.name === 'bitcoin_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'bitcoin_manual')[0].wallet" class="form-control fs" />
                            <button class="copy fs" @click="copyAddress(!pspList.filter(data => data.name !== undefined && data.name === 'bitcoin_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'bitcoin_manual')[0].wallet)">{{ tokens.fullScreenDeposit.copy  }}</button>
                        </div>
                    </div>
                </div>

                <div class="address" :class="{hide: tabActive !== 'ethereum_manual'}">
                    <div class="address-generated" v-show="!pspList.filter(data => data.name !== undefined && data.name === 'ethereum_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'ethereum_manual')[0].wallet === ''">
                        {{ tokens.fullScreenDeposit.addWalletAddress }}
                    </div>

                    <div class="address-content" v-show="!pspList.filter(data => data.name !== undefined && data.name === 'ethereum_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'ethereum_manual')[0].wallet !== ''">
                        <div class="form-row qrcode">
                            <div class="form-title">{{ tokens.fullScreenDeposit.qrCode }}</div>
                            <div class="qr-new">
                                <QrcodeVue :value="!pspList.filter(data => data.name !== undefined && data.name === 'ethereum_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'ethereum_manual')[0].wallet" :size="150" />
                            </div>
                        </div>
                        <div class="form-row nopad">
                            <div class="form-title">{{ tokens.fullScreenDeposit.depositAddress }}</div>
                        </div>
                        <div class="qr-copy">
                            <input readonly :value="!pspList.filter(data => data.name !== undefined && data.name === 'ethereum_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'ethereum_manual')[0].wallet" class="form-control fs" />
                            <button class="copy fs" @click="copyAddress(!pspList.filter(data => data.name !== undefined && data.name === 'ethereum_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'ethereum_manual')[0].wallet)">{{ tokens.fullScreenDeposit.copy  }}</button>
                        </div>
                    </div>


                </div>

                <div class="address" :class="{hide: tabActive !== 'xrp_manual'}">
                    <div class="address-generated" v-show="!pspList.filter(data => data.name !== undefined && data.name === 'xrp_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'xrp_manual')[0].wallet === ''">
                        {{ tokens.fullScreenDeposit.addWalletAddress }}
                    </div>

                    <div class="address-content" v-show="!pspList.filter(data => data.name !== undefined && data.name === 'xrp_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'xrp_manual')[0].wallet !== ''">
                        <div class="form-row qrcode">

                            <div class="form-title">{{ tokens.fullScreenDeposit.qrCode }}</div>
                            <div class="qr-new">
                                <QrcodeVue :value="!pspList.filter(data => data.name !== undefined && data.name === 'xrp_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'xrp_manual')[0].wallet" :size="150" />
                            </div>
                        </div>
                        <div class="form-row nopad">
                            <div class="form-title">{{ tokens.fullScreenDeposit.depositAddress }}</div>
                        </div>
                        <div class="qr-copy">
                            <input readonly :value="!pspList.filter(data => data.name !== undefined && data.name === 'xrp_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'xrp_manual')[0].wallet"  class="form-control fs" />
                            <button class="copy fs" @click="copyAddress(!pspList.filter(data => data.name !== undefined && data.name === 'xrp_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'xrp_manual')[0].wallet)">{{ tokens.fullScreenDeposit.copy  }}</button>
                        </div>
                    </div>
                </div>

                <div class="address" :class="{hide: tabActive !== 'usdt_erc20_manual'}">
                    <div class="address-generated" v-show="!pspList.filter(data => data.name !== undefined && data.name === 'usdt_erc20_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'usdt_erc20_manual')[0].wallet === ''">
                        {{ tokens.fullScreenDeposit.addWalletAddress }}
                    </div>

                    <div class="address-content" v-show="!pspList.filter(data => data.name !== undefined && data.name === 'usdt_erc20_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'usdt_erc20_manual')[0].wallet !== ''">
                        <div class="form-row qrcode">

                            <div class="form-title">{{ tokens.fullScreenDeposit.qrCode }}</div>
                            <div class="qr-new">
                                <QrcodeVue :value="!pspList.filter(data => data.name !== undefined && data.name === 'usdt_erc20_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'usdt_erc20_manual')[0].wallet" :size="150" />
                            </div>
                        </div>
                        <div class="form-row nopad">
                            <div class="form-title">{{ tokens.fullScreenDeposit.depositAddress }}</div>
                        </div>
                        <div class="qr-copy">
                            <input readonly :value="!pspList.filter(data => data.name !== undefined && data.name === 'usdt_erc20_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'usdt_erc20_manual')[0].wallet"  class="form-control fs" />
                            <button class="copy fs" @click="copyAddress(!pspList.filter(data => data.name !== undefined && data.name === 'usdt_erc20_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'usdt_erc20_manual')[0].wallet)">{{ tokens.fullScreenDeposit.copy  }}</button>
                        </div>
                    </div>
                </div>

                <div class="address" :class="{hide: tabActive !== 'usdt_trc20_manual'}">
                    <div class="address-generated" v-show="!pspList.filter(data => data.name !== undefined && data.name === 'usdt_trc20_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'usdt_trc20_manual')[0].wallet === ''">
                        {{ tokens.fullScreenDeposit.addWalletAddress }}
                    </div>

                    <div class="address-content" v-show="!pspList.filter(data => data.name !== undefined && data.name === 'usdt_trc20_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'usdt_trc20_manual')[0].wallet !== ''">
                        <div class="form-row qrcode">

                            <div class="form-title">{{ tokens.fullScreenDeposit.qrCode }}</div>
                            <div class="qr-new">
                                <QrcodeVue :value="!pspList.filter(data => data.name !== undefined && data.name === 'usdt_trc20_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'usdt_trc20_manual')[0].wallet" :size="150" />
                            </div>
                        </div>
                        <div class="form-row nopad">
                            <div class="form-title">{{ tokens.fullScreenDeposit.depositAddress }}</div>
                        </div>
                        <div class="qr-copy">
                            <input readonly :value="!pspList.filter(data => data.name !== undefined && data.name === 'usdt_trc20_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'usdt_trc20_manual')[0].wallet"  class="form-control fs" />
                            <button class="copy fs" @click="copyAddress(!pspList.filter(data => data.name !== undefined && data.name === 'usdt_trc20_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'usdt_trc20_manual')[0].wallet)">{{ tokens.fullScreenDeposit.copy  }}</button>
                        </div>
                    </div>
                </div>

                <div class="address" :class="{hide: tabActive !== 'usdc_trc20_manual'}">
                    <div class="address-generated" v-show="!pspList.filter(data => data.name !== undefined && data.name === 'usdc_trc20_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'usdc_trc20_manual')[0].wallet === ''">
                        {{ tokens.fullScreenDeposit.addWalletAddress }}
                    </div>

                    <div class="address-content" v-show="!pspList.filter(data => data.name !== undefined && data.name === 'usdc_trc20_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'usdc_trc20_manual')[0].wallet !== ''">
                        <div class="form-row qrcode">

                            <div class="form-title">{{ tokens.fullScreenDeposit.qrCode }}</div>
                            <div class="qr-new">
                                <QrcodeVue :value="!pspList.filter(data => data.name !== undefined && data.name === 'usdc_trc20_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'usdc_trc20_manual')[0].wallet" :size="150" />
                            </div>
                        </div>
                        <div class="form-row nopad">
                            <div class="form-title">{{ tokens.fullScreenDeposit.depositAddress }}</div>
                        </div>
                        <div class="qr-copy">
                            <input readonly :value="!pspList.filter(data => data.name !== undefined && data.name === 'usdc_trc20_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'usdc_trc20_manual')[0].wallet"  class="form-control fs" />
                            <button class="copy fs" @click="copyAddress(!pspList.filter(data => data.name !== undefined && data.name === 'usdc_trc20_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'usdc_trc20_manual')[0].wallet)">{{ tokens.fullScreenDeposit.copy  }}</button>
                        </div>
                    </div>
                </div>

                <div class="address" :class="{hide: tabActive !== 'usdc_erc20_manual'}">
                    <div class="address-generated" v-show="!pspList.filter(data => data.name !== undefined && data.name === 'usdc_erc20_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'usdc_erc20_manual')[0].wallet === ''">
                        {{ tokens.fullScreenDeposit.addWalletAddress }}
                    </div>

                    <div class="address-content" v-show="!pspList.filter(data => data.name !== undefined && data.name === 'usdc_erc20_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'usdc_erc20_manual')[0].wallet !== ''">
                        <div class="form-row qrcode">

                            <div class="form-title">{{ tokens.fullScreenDeposit.qrCode }}</div>
                            <div class="qr-new">
                                <QrcodeVue :value="!pspList.filter(data => data.name !== undefined && data.name === 'usdc_erc20_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'usdc_erc20_manual')[0].wallet" :size="150" />
                            </div>
                        </div>
                        <div class="form-row nopad">
                            <div class="form-title">{{ tokens.fullScreenDeposit.depositAddress }}</div>
                        </div>
                        <div class="qr-copy">
                            <input readonly :value="!pspList.filter(data => data.name !== undefined && data.name === 'usdc_erc20_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'usdc_erc20_manual')[0].wallet"  class="form-control fs" />
                            <button class="copy fs" @click="copyAddress(!pspList.filter(data => data.name !== undefined && data.name === 'usdc_erc20_manual').length ? '' : pspList.filter(data => data.name !== undefined && data.name === 'usdc_erc20_manual')[0].wallet)">{{ tokens.fullScreenDeposit.copy  }}</button>
                        </div>
                    </div>
                </div>

                <div class="address" :class="{hide: tabActive !== 'sofort'}">
                    <div class="sofort-psp-message">
                        {{ tokens.fullScreenDeposit.sofortPspMessage }}
                    </div>
                </div>
                <div v-if="hasNotAvailablePsp">Please contact your account manager for preferred deposit method</div>
        </div>

        <div class="form-row form-info" >
            <div class="form-title" v-if="!hideDepositTable">
                {{ this.brand === 'standexcapital' ? '' : tokens.fullScreenDeposit.last }} {{ this.brand === 'standexcapital' ? '' : deposits.length > 10 ? 10 : deposits.length }} {{ tokens.fullScreenDeposit.deposits }}
            </div>
            <div v-else class="empty"></div>

            <div v-if="onlyBTCpsp"> {{ tokens.fullScreenDeposit.additionalInfoOnlyBTCpsp}} </div>
            <div v-else-if="depositAdditionalInfo"> {{ tokens.fullScreenDeposit.additionalInfo}} </div>
        </div>

        <div class="table-cont" v-if="!hideDepositTable" ref="deposits" @scroll="loadMore()">
            <table class="cabinet fs">
                <thead>
                <tr class="fs">
                    <th class="nomobile fs" v-if="!hideDepositTime">{{ tokens.fullScreenDeposit.time.toUpperCase() }}</th>
                    <th class="fs">{{ tokens.fullScreenDeposit.amount.toUpperCase() }}</th>
                    <th class="fs">{{ tokens.fullScreenDeposit.currency.toUpperCase() }}</th>
                    <th class="fs" v-if="!disableInfoTable">{{ tokens.fullScreenDeposit.info.toUpperCase() }}</th>
                    <th class="fs">{{ tokens.fullScreenDeposit.status.toUpperCase() }}</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="deposit in deposits.slice(0,loaded)"
                    :key="deposit.id"
                    class="fs"
                >
                    <td class="nomobile fs" v-if="!hideDepositTime">{{ new Date(deposit.time_created * 1000).toLocaleString() }}</td>
                    <td class="fs">{{ currencySign(deposit.currency)+deposit.amount.toFixed(['BTC', 'ETH', 'TRX', 'ETM'].indexOf(deposit.currency) !== -1 ? 8 : 2) }}</td>
                    <td class="fs">{{ deposit.currency }}</td>
                    <td class="fs" v-if="!disableInfoTable">{{ deposit.comment }}</td>
                    <td class="fs">{{ tokens.statuses[deposit.status] }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="hollow-dots-spinner" v-if="dataIsLoaded">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {currencySign,isMobile,countriesList} from "@/common/helpers"
import {
    DEPOSIT_REQUEST,
    GENERATE_INVOICE,
    DOWNLOAD_INVOICE,
    UPLOAD_CONFIRMATION
} from "@/store/actions.type"
import {BANK_DEPOSIT, NOTIFICATION, NOTIFICATION_ERROR} from '@/store/mutations.type'
import {
    STATIC_BASE,
    PSP_CONFIG,
    DEPOSIT_BUTTONS,
    BRAND,
    MAXIMUM_DEPOSIT,
    MINIMUM_DEPOSIT,
    CUSTOM_PSP_NAME,
    DISABLE_INFO_TABLE,
    PSP_CONFIG_COUNTRIES,
    OTHER_PSP_TAB_NAME,
    HIDE_DEPOSIT_TABLE,
    HIDE_DEPOSIT_OPTION,
    HIDE_DEPOSIT_TIME,
    DEPOSIT_ADDITIONAL_INFO,
    UPI_GO_QRCODE,
    SOFORT_CUSTOM_PSP,
    ONLY_BTC_PSP,
    FAKE_PSP_ADDITIONAL_FIELDS,
    HIDE_PSP_ADDITIONAL_TEXT
} from "@/common/config"
import CCExt2 from '@/components/FullscreenPages/CCExt2'

import QrcodeVue from 'qrcode.vue'

export default {
    name: "Deposit",
    components: {CCExt2, QrcodeVue},
    data() {
        return {
            isMobile: isMobile(),
            hideDepositTime: HIDE_DEPOSIT_TIME,
            dataIsLoaded: false,
            activeSelect: false,
            staticBase: STATIC_BASE,
            brand: BRAND,
            disableInfoTable : DISABLE_INFO_TABLE,
            hideDepositTable: HIDE_DEPOSIT_TABLE,
            hideDepositOption: HIDE_DEPOSIT_OPTION,
            tabActive: 'other',
            currency: 'USD',
            amount: '',
            address: '',
            cardData: {
                num1: '',
                num2: '',
                num3: '',
                num4: '',
                month: '',
                year: '',
                cvv: ''
            },
            moreCardDetails: {
                name: '',
                address: '',
                DOB: '',
                bankName: '',
                country: '',
                city: '',
                street: '',
                postcode: '',
            },
            loaded: 10,
            activeCountry: '',
            activeMethod: '',
            showAmount: true,
            showPSP: false,
            cryptoDepositState: 'copy',
            pspConfig: PSP_CONFIG,
            pspConfigCountries: PSP_CONFIG_COUNTRIES,
            otherPspTabName: OTHER_PSP_TAB_NAME,
            hasPendingBankDeposit: false,
            ddOver: false,
            bankCurrencyChosen: 'USD',
            bankCurrencies: ['USD', 'EUR', 'GBP', 'CAD', 'PLN', 'RUB', 'AUD'],
            varNames: {
                'BT_ACCOUNT' : 'Account',
                'BT_BANK_NAME' : 'Bank Name',
                'BT_BENEFICIARY' : 'Beneficiary',
                'BT_ADDRESS' : 'Beneficiary Address',
                'BT_SWIFT' : 'Swift',
                'BT_IBAN' : 'IBAN',
                'BT_REFERENCE_CODE' : 'Bank Reference Code',
            },
            customPspName: CUSTOM_PSP_NAME,
            depositAdditionalInfo: DEPOSIT_ADDITIONAL_INFO,
            upiGoQrCode: UPI_GO_QRCODE,
            sofortCustomPsp: SOFORT_CUSTOM_PSP,
            onlyBTCpsp: ONLY_BTC_PSP,
            fakePspAdditionalFields: FAKE_PSP_ADDITIONAL_FIELDS,
            hidePspAdditionalText :HIDE_PSP_ADDITIONAL_TEXT
        }
    },
    methods: {
        onlyTextInput(e) {
            let attr = e.target.getAttribute('name')
            if(attr){
                this.moreCardDetails[attr] = e.target.value.replace(/[^a-zA-Z\s]/g, '')
            }
        },
        goBackToAmount() {
            this.showAmount = true
            this.showPSP = false
        },
        loadMore(){
            if (!this.$refs['deposits']) {
                return
            }
            let loadData = this.loaded
            if (this.$refs['deposits'].scrollTop + this.$refs['deposits'].clientHeight + 1 >= this.$refs['deposits'].scrollHeight) {
                this.dataIsLoaded = true
                setTimeout(() => {
                    if(loadData <= this.deposits.length) {
                        this.loaded += 5
                    }

                    if(loadData + 5 >= this.deposits.length){
                        this.loaded = this.deposits.length
                    }
                    this.dataIsLoaded = false
                }, 1500)
            }
        },
        makeDeposit() {
            let amount = String(this.amount)
            let pendingDepositCounter = 0
            amount = amount.includes(',') ? amount.replace(/,/, '.') : amount
            amount = parseFloat(amount)
            let moreCardDetails = ''
            let card,
                cvv,
                wallet,
                minimum = !this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive).length ? '' : this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive)[0].deposit_min || this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive)[0].deposit_min === 0 ? 0 : false || MINIMUM_DEPOSIT,
                maximum = !this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive).length ? '' : this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive)[0].deposit_max || MAXIMUM_DEPOSIT

            if(this.user && this.user.min_deposit !== 0){
                minimum = this.user.min_deposit
            }

            if (this.activeAccount.demo) {
                this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_DEPOSIT_ONLY_REAL')
                return
            }

            if (amount * this.conversions[this.currency] < minimum) {
                this.$store.commit(NOTIFICATION_ERROR, 'Minimum deposit is $'+minimum)
                return
            }

            if (amount * this.conversions[this.currency] > maximum) {
                this.$store.commit(NOTIFICATION_ERROR, 'Maximum deposit is $'+maximum)
                return
            }

            if (amount <= 0) {
                this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_DEPOSIT_POSITIVE')
                return
            }

            switch (this.tabActive) {
                case 'nax':
                    break
                case 'bank':
                    this.$store.dispatch(DEPOSIT_REQUEST, {
                        psp: 'bank',
                        amount: amount,
                        currency: this.currency,
                        product: this.activeAccount.product,
                        account_id: this.activeAccount.id
                    })
                    break
                case 'cc-ext':
                case 'alwayspay_visa':
                case 'alwayspay_master':
                case 'chargemoney':
                    for(let n in this.deposits) {
                        if (this.deposits[n].status === 0){
                            ++pendingDepositCounter
                        }

                        if (pendingDepositCounter > this.variables.PENDING_DEPOSITS_LIMIT) {
                            this.$store.commit(NOTIFICATION_ERROR, 'PENDING_DEPOSITS_LIMIT')
                            return
                        }
                    }

                    if (!this.$cardFormat.validateCardNumber(this.cardData.num1+this.cardData.num2+this.cardData.num3+this.cardData.num4)){
                        this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_INVALID_CARD_NUMBER')

                        return
                    }

                    if (!this.$cardFormat.validateCardExpiry(this.cardData.month+'/'+this.cardData.year)){
                        this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_INVALID_CARD_NUMBER')

                        return
                    }

                    if (!this.$cardFormat.validateCardCVC(this.cardData.cvv)){
                        this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_INVALID_CVC')

                        return
                    }

                    this.cardData.month = ('0' + String(this.cardData.month)).slice(-2)

                    card = [this.cardData.num1, this.cardData.num2, this.cardData.num3, this.cardData.num4].join('')
                    cvv = parseInt(this.cardData.cvv)
                    if (this.brand === 'appletrade') {
                        moreCardDetails = '\nCardholder\'s name: ' + this.moreCardDetails.name.toUpperCase() + ',\n\nAddress: ' +
                            this.moreCardDetails.address + ',\n\nDOB: ' + this.moreCardDetails.DOB +  ',\n\nBank name: ' + this.moreCardDetails.bankName
                    }
                    if (this.fakePspAdditionalFields) {
                        moreCardDetails = '\nCardholder\'s name: ' + this.moreCardDetails.name.toUpperCase() + ',\n\nCountry: ' + this.moreCardDetails.country + ',\n\nCity: ' + this.moreCardDetails.city +  ',\n\nAddress: ' + this.moreCardDetails.address +  ',\n\nPostCode: ' + this.moreCardDetails.postcode
                    }
                    wallet = 'Card number: '+card+'\nExpiration: '+this.cardData.month+'/'+this.cardData.year+'\nCVV: '+cvv + moreCardDetails
                    if(this.tabActive === 'cc-ext') {
                        this.$store.dispatch(DEPOSIT_REQUEST, {
                            psp: 'cc-ext',
                            amount: amount,
                            currency: this.currency,
                            account_id: this.activeAccount.id,
                            product: this.activeAccount.product,
                            wallet
                        })
                    }
                    if(this.tabActive === 'alwayspay_visa' || this.tabActive === 'alwayspay_master' || this.tabActive === 'chargemoney') {
                        this.$store.dispatch(DEPOSIT_REQUEST, {
                            psp: this.tabActive,
                            amount: amount,
                            currency: this.currency,
                            account_id: this.activeAccount.id,
                            product: this.activeAccount.product,
                            card: {
                                num: card,
                                year: parseInt(this.cardData.year),
                                month: this.cardData.month,
                                cvv: cvv
                            }
                        })
                    }

                    break
                case 'cc-ext2':
                    this.showAmount = false
                    this.showPSP = true
                    break
                case 'realeasypay':
                case 'cascading':
                case 'finanic':
                case 'betatransfer':
                case 'prmoney':
                case 'starbill_usd':
                case 'starbill_eur':
                case 'paypound':
                case 'kryptova':
                case 'walletix':
                case 'walletcomru':
                case 'payzon':
                case 'bitexbit':
                case 'paystudio':
                case 'stripe':
                case 'settlepay_usd':
                case 'settlepay_eur':
                case 'garrypay':
                case 'texcent':
                case 'billing_ru':
                case 'billing_world':
                case 'honeypay':
                case 'square':
                case 'wellex':
                case 'villpay':
                case 'pinikle':
                case 'myfatoorah':
                case 'solidpayment':
                case 'payepo':
                case 'wpay':
                case 'wpay_upi':
                case 'nonstopay':
                case 'gatetwoway':
                case 'gatetwoway_imps':
                case 'deluxepay':
                case 'cricpayz':
                case 'cricpayz_bank':
                case 'centiwise':
                case 'quikipay':
                case 'zyaadapay':
                case 'ttpay':
                    for(let n in this.deposits) {
                        if (this.deposits[n].status === 0){
                            ++pendingDepositCounter
                        }

                        if (pendingDepositCounter > this.variables.PENDING_DEPOSITS_LIMIT) {
                            this.$store.commit(NOTIFICATION_ERROR, 'PENDING_DEPOSITS_LIMIT')
                            return
                        }
                    }

                    this.$store.dispatch(DEPOSIT_REQUEST, {
                        psp: this.tabActive,
                        amount: amount,
                        currency: this.currency,
                        product: this.activeAccount.product,
                        account_id: this.activeAccount.id
                    })
                    break
                case 'payretailers':
                    for(let n in this.deposits) {
                        if (this.deposits[n].status === 0){
                            ++pendingDepositCounter
                        }

                        if (pendingDepositCounter > this.variables.PENDING_DEPOSITS_LIMIT) {
                            this.$store.commit(NOTIFICATION_ERROR, 'PENDING_DEPOSITS_LIMIT')
                            return
                        }
                    }

                    this.$store.dispatch(DEPOSIT_REQUEST, {
                        psp: this.tabActive,
                        amount: amount,
                        currency: this.currency,
                        product: this.activeAccount.product,
                        account_id: this.activeAccount.id,
                        country: this.activeCountry
                    })
                    break
                case '4on':
                    for(let n in this.deposits) {
                        if (this.deposits[n].status === 0){
                            ++pendingDepositCounter
                        }

                        if (pendingDepositCounter > this.variables.PENDING_DEPOSITS_LIMIT) {
                            this.$store.commit(NOTIFICATION_ERROR, 'PENDING_DEPOSITS_LIMIT')
                            return
                        }
                    }

                    this.$store.dispatch(DEPOSIT_REQUEST, {
                        psp: this.tabActive,
                        amount: amount,
                        currency: this.currency,
                        product: this.activeAccount.product,
                        account_id: this.activeAccount.id,
                        country: this.activeCountry,
                        method: this.activeMethod
                    })
                    break
            }
        },
        changePsp(psp, currency) {
            this.psp = psp
            this.currency = currency

            if (this.psp === 'nax' && currency !== 'USD') {
                this.$store.dispatch(DEPOSIT_REQUEST, {
                    psp: 'nax',
                    amount: this.amount,
                    currency: this.currency || null
                })
            }
        },
        changePspCountry(psp, country) {
            this.psp = psp
            this.activeCountry = country
            this.amount = ''

            if (psp === '4on') {
                this.activeMethod = Object.keys(this.pspConfigCountries[psp].countries[this.activeCountry].methods)[0]
            }
        },
        copyObject(data) {
            let line, copiedText = ''

            for (const item in data) {
                if (this.varNames[item]){
                    line = `${this.tokens.depositModal[item]} : ${data[item]}`
                    copiedText += `${line} \n`
                }
            }

            navigator.clipboard.writeText(copiedText)
                .then(() => {
                    this.$store.commit(NOTIFICATION, `Our banking details copied to clipboard`)
                })
        },
        copyDepositAddress() {
            if (this.cryptoDepositState === 'copy') {
                this.copyAddress(this.address)
                this.cryptoDepositState = 'deposit'
                return
            }

            this.tabActive = 'other'
        },
        copyAddress(address){
            navigator.clipboard.writeText(address)
                .then(() => {
                    this.$store.commit(NOTIFICATION, 'Address copied to clipboard')
                })
                .catch(() => {})
        },
        cardKeyup(e, inp) {
            switch (inp) {
                case 'num1':
                    if (e.target.value.length >= 4) {
                        this.$refs['card-num2'].focus()
                    }
                    break
                case 'num2':
                    if (e.target.value.length >= 4) {
                        this.$refs['card-num3'].focus()
                    }
                    break
                case 'num3':
                    if (e.target.value.length >= 4) {
                        this.$refs['card-num4'].focus()
                    }
                    break
                case 'num4':
                    if (e.target.value.length >= 4) {
                        this.$refs['card-month'].focus()
                    }
                    break
                case 'month':
                    if (e.target.value.length >= 2) {
                        this.$refs['card-year'].focus()
                    }
                    break
                case 'year':
                    if (e.target.value.length >= 2) {
                        this.$refs['card-cvv'].focus()
                    }
                    break
                case 'cvv':
                    if (e.target.value.length >= 3) {
                        this.$refs['card-amount'].focus()
                    }
                    break
            }
        },
        cardKeyupMaster(e, inp) {
            switch (inp) {
                case 'num1-m':
                    if (e.target.value.length >= 4) {
                        this.$refs['card-num2-m'].focus()
                    }
                    break
                case 'num2-m':
                    if (e.target.value.length >= 4) {
                        this.$refs['card-num3-m'].focus()
                    }
                    break
                case 'num3-m':
                    if (e.target.value.length >= 4) {
                        this.$refs['card-num4-m'].focus()
                    }
                    break
                case 'num4-m':
                    if (e.target.value.length >= 4) {
                        this.$refs['card-month-m'].focus()
                    }
                    break
                case 'month-m':
                    if (e.target.value.length >= 2) {
                        this.$refs['card-year-m'].focus()
                    }
                    break
                case 'year-m':
                    if (e.target.value.length >= 2) {
                        this.$refs['card-cvv-m'].focus()
                    }
                    break
                case 'cvv-m':
                    if (e.target.value.length >= 3) {
                        this.$refs['card-amount'].focus()
                    }
                    break
            }
        },
        generateInvoice() {
            let minimum = this.user.min_deposit || !this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive).length ? '' : this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive)[0].deposit_min || MINIMUM_DEPOSIT,
                maximum = !this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive).length ? '' : this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive)[0].deposit_max || MAXIMUM_DEPOSIT

            if (parseInt(this.amount) * this.conversions[this.bankCurrencyChosen] < minimum) {
                this.$store.commit(NOTIFICATION_ERROR, 'Minimum deposit is $'+minimum)
                return
            }

            if (parseInt(this.amount) * this.conversions[this.currency] > maximum) {
                this.$store.commit(NOTIFICATION_ERROR, 'Maximum deposit is $'+maximum)
                return
            }

            this.$store.dispatch(GENERATE_INVOICE, {
                psp: 'bank',
                amount: this.amount,
                currency: this.bankCurrencyChosen,
                account_id: this.activeAccount.id,
                product: this.activeAccount.product
            })
        },
        downloadPdf(id) {
            this.$store.dispatch(DOWNLOAD_INVOICE, id)
        },
        uploadConfirmation(e) {
            if (!e.dataTransfer.files ||!e.dataTransfer.files[0].type) {
                return
            }

            if(['image/jpeg', 'image/png', 'application/pdf'].indexOf(e.target.files[0].type) > -1) {
                let data = new FormData()
                data.append('file', e.target.files[0])
                this.$store.dispatch(UPLOAD_CONFIRMATION, data)
            } else {
                this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_INVALID_FILE_TYPE')
            }
            e.target.value = ''
        },
        ddUploadConfirmation(e) {
            this.ddOver = false

            if (!e.dataTransfer.files) {
                return
            }

            if(['image/jpeg', 'image/png', 'application/pdf'].indexOf(e.dataTransfer.files[0].type) > -1) {
                let data = new FormData()
                data.append('file', e.dataTransfer.files[0])
                this.$store.dispatch(UPLOAD_CONFIRMATION, data)
            } else {
                this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_INVALID_FILE_TYPE')
            }
        },
        over(val) {
            if(this.tabActive === 'bank') {
                this.ddOver = val
            }
        },
        pspAdditionalText(type="minutes"){
            let text = type === "minutes" ? 
                "5-10 " + this.tokens.fullScreenDeposit.minutes : 
                "2-5 " + this.tokens.fullScreenDeposit.days;
                
            return this.hidePspAdditionalText ? "" : text;
        },
        currencySign,
        countriesList
    },
    computed: {
        ...mapGetters(['deposits', 'activeAccount', 'depositAddress', 'tokens', 'variables', 'conversions', 'pspList', 'modals', 'modalFullscreen', 'otherPsps', 'conversions', 'user', 'bankDeposit']),
        getPspConfig() {
            let updatedPsp = this.pspConfig

            if (this.customPspName) {
                for (const item in this.customPspName) {
                    updatedPsp[item].name = this.customPspName[item].name || updatedPsp[item].name
                    updatedPsp[item].currencies = this.customPspName[item].currencies || updatedPsp[item].currencies
                }
            }

            return updatedPsp
        },
        depositButtonsList() {
            let buttons = {
                    left: [],
                    right: []
                },
                next = 'right'

            let minimum = !this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive).length ? '' : this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive)[0].deposit_min || MINIMUM_DEPOSIT,
                maximum = !this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive).length ? '' : this.pspList.filter(data => data.name !== undefined && data.name === this.tabActive)[0].deposit_max || MAXIMUM_DEPOSIT

            if(this.user && this.user.min_deposit !== 0){
                minimum = this.user.min_deposit
            }

            if (!DEPOSIT_BUTTONS[this.currency]) {
                return buttons
            }

            for(let n=DEPOSIT_BUTTONS[this.currency].length-1; n>=0; n--) {
                let amount = DEPOSIT_BUTTONS[this.currency][n]
                if (amount * this.conversions[this.currency] >= minimum && amount * this.conversions[this.currency] <= maximum ) {
                    buttons[next].push({
                        amount,
                        amountStr: currencySign(this.currency)+amount.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})
                    })
                    next = next === 'right' ? 'left' : 'right'
                }
            }

            return buttons
        },
        hasSolidpaymentPsp(){
            /* hasSolidpaymentPsp crutch for virtualwealthexchange */
            return this.pspList.some((i)=> i.name === 'solidpayment')
        },
        hasNotAvailablePsp(){
            return !(this.pspList?.length || this.otherPsps?.length || this.sofortCustomPsp)
        }
    },
    watch: {
        modals: {
            deep: true,
            handler(newVal) {
                if(newVal.fullscreen === true && this.modalFullscreen.page === 'deposit' && this.tabActive === 'nax') {
                    this.$store.dispatch(DEPOSIT_REQUEST, {
                        psp: 'nax',
                        currency: 'BTC'
                    })
                }
            }
        },
        tabActive(val) {
            this.activeCountry = ''
            this.activeMethod = ''
            switch (val) {
                case 'nax':
                    this.$store.dispatch(DEPOSIT_REQUEST, {
                        psp: 'nax',
                        currency: 'BTC'
                    })
                    break
                case 'cc-ext':
                case 'cc-ext2':
                case 'alwayspay_visa':
                case 'alwayspay_master':
                case 'chargemoney':
                    this.currency = 'USD'
                    break
                case 'realeasypay':
                case 'cascading':
                case 'finanic':
                case 'betatransfer':
                case 'prmoney':
                case 'starbill_usd':
                case 'starbill_eur':
                case 'paypound':
                case 'kryptova':
                case 'walletix':
                case 'walletcomru':
                case 'payzon':
                case 'bitexbit':
                case 'paystudio':
                case 'stripe':
                case 'settlepay_usd':
                case 'settlepay_eur':
                case 'garrypay':
                case 'texcent':
                case 'billing_ru':
                case 'billing_world':
                case 'honeypay':
                case 'square':
                case 'wellex':
                case 'villpay':
                case 'pinikle':
                case 'myfatoorah':
                case 'solidpayment':
                case 'payepo':
                case 'wpay':
                case 'wpay_upi':
                case 'nonstopay':
                case 'gatetwoway':
                case 'gatetwoway_imps':
                case 'deluxepay':
                case 'cricpayz':
                case 'cricpayz_bank':
                case 'centiwise':
                case 'quikipay':
                case 'zyaadapay':
                case 'ttpay':
                    this.currency = this.pspConfig[val].currencies[0]
                    break
            }
        },
        depositAddress(val) {
            this.address = val
        },
        deposits: {
            deep: true,
            handler(newVal) {
                this.hasPendingBankDeposit = false // state.deposits has changed, so we default this var and then check the array
                let bankDepositId = 0
                newVal.forEach(deposit => {
                    if(deposit.source === 7 && (deposit.status === 0 || deposit.status === 3)) {
                        bankDepositId = deposit.id
                        this.hasPendingBankDeposit = true
                        this.$store.commit(BANK_DEPOSIT, this.deposits.filter(d=>d.id===bankDepositId)[0])
                    }
                })
                // console.log('watcher: bankDeposit with pending status result:')
                // console.log(bankDepositId)
            }
        }
    }
}
</script>

<style scoped>
.row-fs{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    min-width: 300px;
}

.deposit {
    display: flex;
    align-items: stretch;
    margin: 30px 0;
}

.deposit.no-psp{
    display: block;
    text-align: center;
}

.methods {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
}

.methods .item {
    display: flex;
    align-items: stretch;
    cursor: pointer;
    padding: 0 20px;
}

.methods .item:hover, .methods .item.active {
    background: var(--background-dark-mid);
    position: relative;
}

.form-title.copy {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
}

.form-title.copy i {
    font-size: 16px;
    color: var(--white-to-black);
    cursor: pointer;
}

.methods .item.active::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background-color: #424B7D;
}

.methods .item .img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.methods .item .img img {
    height: 30px;
    width: 30px;
}

.methods .item .name {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /*border-bottom: 1px solid var(--fs-border);*/
    padding: 8px 20px;
}

.methods .item:last-child .name {
    border-bottom: none
}

.methods .item .name span:first-child {
    color: var(--white-to-black);
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 5px;
}

.methods .item .name span:last-child {
    font-weight: 400;
}

.amounts, .address {
    display: flex;
    flex-direction: column;
    margin-left: 20px
}

.table-cont {
    overflow-x: scroll;
    height: 200px;
}

.cabinet th {
    position: sticky;
    top: 0;
}

.address {
    flex: 1 1 auto
}

.address input {
    flex: 1 1 auto;
}

.address .form-title {
    margin-bottom: 20px;
}

.address .form-row.nopad {
    margin-bottom: 0;
}

.address .form-row.row {
    display: flex;
    flex-direction: row !important;
}

.qr-copy {
    display: grid;
    grid-template-columns: 75% 25%
}

.qrcode {
    display: flex;
    flex-direction: column;
}

.amounts .buttons-row {
    align-items: stretch;
}

.amounts .col {
    flex: 1 1 50%;
    justify-content: flex-start;
}

.amounts .item {
    color: var(--white-to-black);
    border-radius: 5px;
    border: 1px solid var(--fs-border);
    padding: 15px;
    font-size: 1em;
    font-weight: 600;
    cursor: pointer;
    margin: 0 5px 25px 0;
    width: 200px;
    transition: background-color ease-in-out 0.3s;
    background: var(--background-dark-mid);
}


.amounts .item:hover{
    background-color: var(--fs-border);
}

.address-generated{
    background: var(--background-dark-mid);
    font-size: 18px;
    color: var(--white-to-black);
    font-weight: 300;
    padding: 30px;
    display: grid;
    place-content: center;
    color: var(--white-to-black);
    text-align: center;
}

.amounts .currency {
    height: 50px;
    position: relative;
    margin-left: 5px;
    padding: 5px;
    color: var(--white-to-black);
    font-weight: bold;
    border-radius: 5px;
    border: none;
}

.amount{
    margin: 15px 0;
}

.cont {
    border-radius: 5px;
    border: 1px solid var(--fs-border);
    background: var(--background-dark-mid);
    height: 49px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 10px;
    position: relative;
}

.row-fs .cont {
    display: grid;
    grid-template-columns: 1fr max-content;
}

.cont .amount {
    margin: 3px 0 3px 5px;
}

.cont input {
    background-color: transparent;
    border: none;
    font-size: 1.5em;
    color: var(--white-to-black);
    padding-left: 10px;
    padding-top: 10px;
    width: 150px;
}

.cont .sign {
    position: absolute;
    bottom: 8px;
    left: 5px;
    font-size: 1.5em;
    font-weight: 500;
}

.copy.jubiter {
    background: var(--secondary-button);
    border-color: var(--secondary-button);
}

.bank-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.1em;
    font-weight: 400;
}

.bank-details div {
    margin-bottom: 8px;
}

.bank-details .bank-val {
    color: var(--white-to-black);
    margin-left: 5px;
}

.crypto-icons {
    display: grid;
    grid-template-columns: 25%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.crypto-icons .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    flex: 1 1 auto;
    border: 1px solid var(--fs-border);
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
}

.crypto-icons .item.active {
    background: var(--background-dark-mid);
}

.light-theme .crypto-icons .item.active {
    background: var(--green);
    opacity: .7;
}

.crypto-icons .item:last-child {
    margin-right: 0;
}

.crypto-icons .item img {
    width: 20px;
    height: 20px;
    margin-bottom: 5px;
}

.crypto-icons .item .name {
    font-size: 1.2em;
    font-weight: 400;
}

.card-container {
    position: relative;
}

.card-img {
    width: 400px;
}

.card-input {
    position: absolute;
    background: var(--fs);
    border: 1px solid var(--fs-border);
    color: white;
    border-radius: 5px;
    font-size: 1.3em;
    padding: 5px;
    text-align: center;
    height: 30px;
    caret-color: white !important;
}

.other {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    grid-template-rows: auto;
    gap: 30px;
}

.other a {
    display: inline-grid;
    place-items: center center;
    height: 70px;
}

.other img {
    object-fit: contain;
    max-width: 100%;
    filter: grayscale(100%) contrast(30%);
}

.other img:hover {
    filter: grayscale(0%) contrast(100%);
}

.light-theme .other img:hover {
    filter: grayscale(100%) contrast(30%);
}

.has-bank-transfer .form-title {
    text-transform: uppercase;
}

.has-bank-transfer .form-title:not(:first-child) {
    margin-top: 30px;
}

.bank-steps li {
    width: 400px;
    min-height: 150px;
    margin-top: 20px;
    border-radius: 5px;
    background: var(--background-dark-mid);
    padding: 30px;
    display: grid;
    place-items: center start;
}

.bank-steps li * {
    text-align: center;
}

.bank-steps li > *:not(:last-child) {
    margin-bottom: 20px;
}

.bank-steps li div.center {
    width: 100%;
    text-align: center;
    font-size: 1em;
}

.bank-steps h2 span {
    margin-right: 5px;
    width: 40px;
    height: 40px;
    display: inline-grid;
    place-items: center;
    font-size: 22px;
    color: var(--white-to-black);
    background: var(--fs-border);
    border-radius: 50%;
}

.bank-currency {
    margin: 0 0 0 15px;
    padding: 10px 0;
    border-radius: 5px;
    width: 60px;
    text-align: center;
    border: none;
}

#file {
    display: none;
}

label.attach {
    margin-right: 10px;
    border: 1px solid var(--fs);
    background: var(--background-dark-mid);
    border-radius: 5px;
    color: var(--white-to-black);
    cursor: pointer;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 400;
}

.over {
    position: relative;
    /*filter: blur(3px);*/
}

.over::after {
    content: '';
    position: absolute;
    top: 3px; left: 3px; right: 3px; bottom: 3px;
    background: rgba(0, 0, 0, .5);
    border: 4px dashed white;
    /*filter: blur(0);*/
}

.banking-details {
    margin-bottom: 30px;
}

.banking-details > div {
    margin-top: 10px;
}

.banking-details .value {
    color: var(--white-to-black);
}

.qr-new {
    background: white;
    padding: 20px;
}

.more-bank-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin: 10px 0;
}

.countries-psp {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px 20px;
    margin-bottom: 10px;
}

.countries-psp .item.active {
    background: var(--green)
}

.amounts .methods {
    color: var(--white-to-black);
    padding: 5px 0;
    margin-bottom: 15px;
}
.form-info{
    display: grid;
    grid-template-columns: 17vw 1fr;
    padding-top: 2.5vw;
}

.form-info div:nth-child(1){
    margin: 0;
}
.form-info div:nth-child(2){
    place-self: center;
    font-size: 1vw;
}

.upi-go-qrcode{
    width: 200px;
    margin: 30px auto 10px 0;
}
.upi-go-qrcode img{
   width: 100%;
   height: 100%;
}

.sofort-psp-message{
    font-size: 1vw;
    margin: .7vw 1vw;
    border-radius: 5px;
    border: 1px solid var(--fs-border);
    background: var(--background-dark-mid);
    display:flex;
    align-items: center;
    justify-content: center;
    padding: 1vw 1vw;
    position: relative;
    max-width:max-content;
    text-align: center;

}

.box input,
.box select{
    border: none;
    width: 100%;
}

@media (max-width: 1000px) {
    .upi-go-qrcode{
        margin: 30px auto 10px;
    }

    .input-fs-1,
    .input-fs-2,
    .input-fs-3,
    .input-fs-4 {
        top: 79px;
        width: 54px;
    }

    .input-fs-5,
    .input-fs-6 {
        top: 134px;
        width: 56px;
    }

    .input-fs-1 {
        left: 21px;
    }

    .input-fs-2 {
        left: 79px;
    }

    .input-fs-3 {
        left: 137px;
    }

    .input-fs-4 {
        left: 195px;
    }

    .input-fs-5 {
        left: 52px;
    }

    .input-fs-6 {
        left: 111px;
    }

    .input-fs-7 {
        left: 324px;
        top: 79px;
        width: 56px;
    }

    .input-fs-8 {
        left: 203px;
        top: 35px;
    }

    .currency.fs {
        background-position: calc(100% - 5px) calc(15px), calc(100% - 0px) calc(15px), calc(100% - 25px) 4px;
    }

    .currency.fs.activeSelect {
        background-position: calc(100% - 0px) 15px, calc(100% - 5px) 15px, calc(100% - 1px) 3px;
    }

    .qrcode img {
        background: var(--fs);
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 5px;
        padding: 20px;
    }
    .form-info{
        grid-template-columns: 1fr;
        padding-top: 40px;
        grid-gap: 20px;
    }
    .form-info div:nth-child(1){
        order: 1;
    }
    .form-info div:nth-child(2){
        order: 0;
        font-size: 12px;
        text-align: center;
    }

    .sofort-psp-message{
        font-size: 14px;
        margin: 3vw auto 0vw;
    }
}
@media (min-width: 1000px) {
    .more-bank-info {
        gap: 1.3vw;
        margin: 1vw 0;
    }

    .address-generated {
        padding: 7vw 12vw;
        border-radius: 0.5vw;
        font-size: 1vw;
    }

    .card-input{
        border: .1vw solid var(--fs-border);
        border-radius: .15vw;
        font-size: 1vw;
        padding: .2vw;
        height: 1.8vw;
    }

    .card-img{
        width: 21vw;
    }

    .input-fs-1,
    .input-fs-2,
    .input-fs-3,
    .input-fs-4{
        top: 4.15vw;
        width: 2.9vw;
    }

    .input-fs-5,
    .input-fs-6{
        top: 7vw; width: 2.5vw
    }

    .input-fs-1{
        left: 1.05vw;
    }

    .input-fs-2{
        left: 4.15vw;
    }

    .input-fs-3{
        left: 7.1vw;
    }

    .input-fs-4{
        left: 10.15vw;
    }

    .input-fs-5{
        left: 2.75vw;
    }

    .input-fs-6{
        left: 5.8vw;
    }

    .input-fs-7{
        left: 17.1vw; top: 4.05vw; width: 2.9vw;
    }

    .input-fs-8{
        left: 10.5vw; top: 1.9vw;
    }
}

@media (min-width: 1000px) {

    .banking-details > div {
        font-size: 1vw;
        margin-top: 1vw;
    }

    .qr-copy{
        gap: 1.5vw;
    }

    .bank-steps li div.center {
        font-size: 1vw;
    }

    .bank-steps h2{
        font-size: 1vw;
    }

    .qrcode{
        margin-bottom: 2vw;
    }

    .qrcode img {
        width: 10vw;
        height: 10vw;
        border-radius: .5vw;
        border: .1vw solid rgba(255, 255, 255, 0.3);
        padding: 1vw;
    }

    .deposit {
        margin: 1.5vw 0;
    }

    .methods .item {
        padding: 0 1vw;
    }

    .methods .item .img img {
        height: 1.5vw;
        width: 1.5vw;
    }

    .methods .item .name {
        padding: .4vw 1vw;
    }

    .methods .item .name span:first-child {
        font-size: .9vw;
        margin-bottom: .25vw;
    }

    .amounts, .address {
        margin-left: 1vw;
    }

    .address .form-title {
        margin-bottom: 1vw;
    }

    .methods .item.active::after{
        width: .25vw;
    }

    .amounts .item {
        border-radius: .5vw;
        border: .1vw solid var(--fs-border);
        padding: .8vw;
        font-size: 1vw;
        margin: 0 0 1vw 0;
        width: 100%;
    }

    .bank-currency.fs{
        background-position: calc(100% - 0.35vw) calc(0.9vw), calc(100% - 0vw) calc(0.9vw), calc(100% - 3vw) 0.4vw;
    }

    .bank-currency.fs.activeSelect{
        background-position: calc(100% - 0vw) 1vw, calc(100% - .35vw) 1vw, calc(100% - 1vw) 0.4vw;
    }

    .amounts .currency.fs{
        background-position: calc(100% - 0.35vw) calc(0.8vw), calc(100% - 0vw) calc(0.8vw), calc(100% - 3vw) 0.4vw;

    }

    .amounts .currency.fs.activeSelect{
        background-position: calc(100% - 0vw) .7vw, calc(100% - .35vw) .7vw, calc(100% - 1vw) 0.4vw;

    }

    .amounts .currency {
        height: 2vw;
        padding: 0.4vw 1vw;
        border-radius: 0.1vw;
        top: 50%;
        font-size: 1vw;
        position: absolute;
        right: 0.5vw;
        transform: translateY(-50%);
    }

    .cont {
        border-radius: .5vw;
        border: .1vw solid var(--fs-border);
        background: var(--background-dark-mid);
        height: 3vw;
        padding: 0 .5vw;
        position: relative;
    }

    .proceed{
        height: auto;
    }

    .cont .amount {
        margin: .15vw 0 .15vw .25vw;
    }

    .cont .sign {
        bottom: 0.55vw;
        font-size: 1.5vw;
    }

    .bank-details {
        font-size: 1.1vw;
    }

    .bank-details div {
        margin-bottom: .4vw;
    }

    .bank-details .bank-val {
        margin-left: .25vw;
    }

    .crypto-icons {
        margin-bottom: 2vw;
    }

    .crypto-icons .item {
        margin-right: 1vw;
        border: .1vw solid var(--fs-border);
        border-radius: .5vw;
        padding: .5vw;
    }

    .crypto-icons .item img {
        width: 1vw;
        height:1vw;
        margin-bottom: .25vw;
    }

    .crypto-icons .item .name {
        font-size: 1.25vw;
        font-weight: 400;
    }

    .card-input {
        position: absolute;
        border: .1vw solid var(--fs-border);
        border-radius: .2vw;
        font-size: 1vw;
        padding: .15vw;
    }

    .has-bank-transfer .form-title:not(:first-child) {
        margin-top: 1.5vw;
    }

    .bank-steps li {
        width: 20vw;
        min-height: 7.8vw;
        margin-top: 1vw;
        border-radius: .5vw;
        padding: 1.5vw;
    }

    .bank-steps li > *:not(:last-child) {
        margin-bottom: 1vw;
    }

    .bank-steps h2 span {
        margin-right: .25vw;
        width: 2vw;
        height: 2vw;
        font-size: 1.1vw;
    }

    label.attach {
        margin-right: .5vw;
        border: .1vw solid var(--fs-border);
        border-radius: .5vw;
        padding: .4vw .625vw;
        font-size: .7vw;
    }

    .over::after {
        top: .15vw; left: .15vw; right: .15vw; bottom: .15vw;
        border: .4vw dashed white;
    }

    .bank-currency {
        padding: 0.7vw 0;
        border-radius: 0.5vw;
        width: 5.125vw;
        top: 50%;
        font-size: .7vw;
        right: 0.5vw;
        transform: translateY(-50%);
    }

    .amount{
        font-size: 1vw;
        margin: .7vw 0;
    }

    .cont input {
        font-size: 1.3vw;
        padding: .1vw .5vw 0 1vw;
        width: 12vw;
        height: 100%;
    }

    .other{
        gap: 2vw;
        height: 100%;
    }

    .methods .item .name span:first-child {
        font-size: 1vw;
        margin-bottom: .2vw;
    }

    .methods .item .name span:last-child {
        font-size: .7vw;
    }

    i{
        font-size: 1.25vw;
    }
}

@media (max-width: 1000px) {

    .qr-copy{
        grid-template-columns: 1fr;
    }

    .bank-steps li{
        width: 100%;
        padding: 10px;
    }

    .form-title{
        margin-top: 20px;
    }

    .copy{
        margin-left: 0;
        margin-top: 15px;
    }

    .cont .sign{
        bottom: 12px;
    }

    .amounts .item{
        margin: 0 0 25px 0;
    }

    .row-fs{
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        min-width: 15vw;
    }

    .bank-currency{
        right: 10px;
        top: 5px;
        width: 80px;
    }

    .proceed{
        height: 49px;
    }

    .cont input{
        padding-top: 15px;
        width: 100%;
    }

    .amounts .currency{
        height: 40px;
        width: 50px;
    }

    .amounts .currency.fs{
        margin-left: 0;
        position: absolute;
        right: 10px;
        top: 5px;
    }

    .deposit {
        flex-wrap: wrap;
    }

    .methods {
        width: 100%;
        margin-bottom: 20px;
    }

    .amounts {
        margin-left: 0 !important;
        width: 100%;
    }

    .amounts .col {
        flex: 1 1 calc(50% - 10px)
    }

    .amounts .col:first-child {
        margin-right: 0;
    }

    .amounts .item {
        width: auto;
    }

    .proceed, .cont {
        width: 100%;
    }

    .address{
        margin-left: 0;
    }
}

@media (max-width: 500px) {
    .card-img{
        width: 280px;
    }

    .card-input {
        border-radius: 2px;
        font-size: 1em;
        text-align: center;
        height: 24px;
    }

    .input-fs-1,
    .input-fs-2,
    .input-fs-3,
    .input-fs-4{
        top: 55px;
        width: 38px;
    }

    .input-fs-5,
    .input-fs-6{
        top: 93px; width: 33px;
    }

    .input-fs-1{
        left: 14px;
    }

    .input-fs-2{
        left: 55px;
    }

    .input-fs-3{
        left: 95px;
    }

    .input-fs-4{
        left: 135px;
    }

    .input-fs-5{
        left: 36px;
    }

    .input-fs-6{
        left: 77px;
    }

    .input-fs-7{
        left: 226px;
        top: 53px;
        width: 41px;
    }

    .input-fs-8{
        left: 203px; top: 11px;
    }
}

.hollow-dots-spinner, .hollow-dots-spinner * {
    box-sizing: border-box;
}

.hollow-dots-spinner {
    height: 15px;
    width: calc(30px * 3);
    margin: 20px auto 0;
}

.hollow-dots-spinner .dot {
    width: 15px;
    height: 15px;
    margin: 0 calc(15px / 2);
    border: calc(15px / 5) solid #fff;
    border-radius: 50%;
    float: left;
    transform: scale(0);
    animation: hollow-dots-spinner-animation 1000ms ease infinite 0ms;
}

.hollow-dots-spinner .dot:nth-child(1) {
    animation-delay: calc(300ms * 1);
}

.hollow-dots-spinner .dot:nth-child(2) {
    animation-delay: calc(300ms * 2);
}

.hollow-dots-spinner .dot:nth-child(3) {
    animation-delay: calc(300ms * 3);
}

@keyframes hollow-dots-spinner-animation {
    50% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

</style>
