<template>
    <div class="chart">
        <div class="chart-col">
            <div class="chart-cont" :id="'chart-'+gridId"></div>
        </div>
        <OrderMenu v-show="currentSym" :chart="chart" :gridId="gridId" :visible="orderMenu" />
        <div class="no-chart" :class="{hide: chart !== false}" v-on:click="showSymbolsModal">
            <i class="material-icons">add</i>
            <span>{{ tokens.chart.addNewChart }}</span>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import * as TradingView from '@/assets/tradingview/charting_library.js'
    import OrderMenu from "@/components/OrderMenuGridWide";
    import {
        BRAND, CDN_ENABLED,
        CHART_FULL_TIMEFRAMES,
        COMPANY_NAME,
        STATIC_BASE,
        TRADINGVIEW_THEME
    } from "@/common/config"
    import {MODAL, MODAL_SYMBOLS, SYMBOL_ACTIVE_UPDATE} from "@/store/mutations.type";
    import {checkSession, isMobile, localStorage} from '@/common/helpers'
    import RestService from "@/common/rest.service";

    export default {
        name: "Chart",
        components: {
            OrderMenu
        },
        props: ['chart', 'gridId'],
        mounted() {
            setInterval(() => {
                if (this.tvWidget && this.chart.id && this.ready) {
                    for(let n in localStorage){
                        if(n.slice(0, 3) === 'tv-' && n !== 'tv-'+this.gridCharts[0] && n !== 'tv-'+this.gridCharts[1] && n !== 'tv-'+this.gridCharts[2] && n !== 'tv-'+this.gridCharts[3]){
                            localStorage.removeItem(n)
                        }
                    }
                    try {
                        this.tvWidget.save((data) => {
                            localStorage.setItem('tv-'+this.chart.id, JSON.stringify(data))
                        })
                    } catch (e) {
                        this.$set(this, 'ready', false)
                        console.log(e)
                    }
                }
            }, 5000)

            document.addEventListener('visibilitychange', () => {
                if (document.hidden) {
                    this.inactiveStart = Date.now()
                } else {
                    if (Date.now() - this.inactiveStart > 10000 && this.tvWidget && this.chart.id && this.ready) {
                        this.tvWidget.chart().resetData()
                    }
                }
            })
        },
        data() {
            return {
                staticBase: STATIC_BASE,
                tvWidget: null,
                tvSession: '',
                tvSessionStartByDate: [0,0,0,0,0,0,0,0],
                readyCallback: null,
                lastCandle: {},
                subs: {},
                orderMenu: isMobile(),
                showOrderActive: false,
                ready: false,
                inactiveStart: 0,
                tradingviewTheme: TRADINGVIEW_THEME,
                brand: BRAND
            }
        },
        methods: {
            showSymbolsModal() {
                this.$store.commit(MODAL, {
                    symbols: true
                })
                this.$store.commit(MODAL_SYMBOLS, {
                    action: 'update',
                    id: null,
                    gridId: this.gridId
                })
            },
            drawChart() {
                if (!this.currentSym) {
                    return
                }

                let chartData = localStorage.getItem('tv-'+this.chart.id),
                    interval = localStorage.getItem('tv-'+this.chart.id+'-interval') || '1'

                try {
                    chartData = JSON.parse(chartData)
                } catch (e) {
                    console.log('No chart data')
                }

                this.ready = false

                this.tvWidget = new TradingView.widget({
                    symbol: this.currentSym,
                    interval,
                    fullscreen: false,
                    autosize: true,
                    container: 'chart-'+this.gridId,
                    saved_data: chartData || undefined,
                    datafeed: {
                        onReady: (callback) => {
                            setTimeout(() => {
                                callback({
                                    exchanges: [COMPANY_NAME],
                                    symbols_types: ['Crypto'],
                                    supported_resolutions: CHART_FULL_TIMEFRAMES
                                })
                            }, 1)
                        },
                        searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
                            let ret = []
                            for(let s in this.symbols) {
                                if (userInput && this.symbols[s].visible && (this.symbols[s].symbol.toLowerCase().includes(userInput.toLowerCase()) || this.symbols[s].alias.toLowerCase().includes(userInput.toLowerCase()))) {
                                    ret.push({
                                        symbol: this.symbols[s].symbol,
                                        full_name: this.symbols[s].alias,
                                        description: this.symbols[s].alias,
                                        type: this.symbols[s].market,
                                        exchange: COMPANY_NAME,
                                        ticker: this.symbols[s].symbol
                                    })
                                }
                            }

                            onResultReadyCallback(ret)
                        },
                        resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
                            let symbol = this.symbols[symbolName]

                            this.ready = false

                            if (symbol === undefined) {
                                setTimeout(() => {
                                    onResolveErrorCallback()
                                }, 1)
                                return
                            }

                            if (this.chart.symbol !== symbol.symbol) {
                                setTimeout(() => {
                                    this.$store.commit(SYMBOL_ACTIVE_UPDATE, {
                                        id: this.chart.id,
                                        symbol: symbol.symbol
                                    })
                                },1)
                            }

                            this.tvSessionStartByDate = [0,0,0,0,0,0,0,0]

                            // let session = []
                            // for(let n=0; n<7; n++) {
                            //     let s = this.symbols[symbolName].sessions[n],
                            //         start = 0,
                            //         stop = 0,
                            //         started = false
                            //
                            //     for(let i=0; i<24; i++) {
                            //         let cur = s[i]
                            //
                            //         if (cur === 0 && !started) {
                            //             start++
                            //             stop++
                            //             continue
                            //         }
                            //
                            //         if (cur === 0 && started) {
                            //             break
                            //         }
                            //
                            //         if (cur === 1 && !started) {
                            //             started = true
                            //             continue
                            //         }
                            //
                            //         if (cur === 1 && started) {
                            //             stop++
                            //             continue
                            //         }
                            //
                            //         if (!started) {
                            //             started = true
                            //             start += cur
                            //             continue
                            //         }
                            //
                            //         if (started) {
                            //             stop += cur
                            //             break
                            //         }
                            //     }
                            //
                            //     let toks = {
                            //         start: String(start).split('.'),
                            //         stop: String(stop+1).split('.')
                            //     }
                            //
                            //     if (start !== 24) {
                            //         session.push(('0'+toks.start[0]).slice(-2)+('0'+(toks.start[1] !== undefined ? Math.round(toks.start[1] * 60 / Math.pow(10, String(toks.start[1]).length)) : 0)).slice(-2)+'-'+('0'+toks.stop[0]).slice(-2)+('0'+(toks.stop[1] !== undefined ? Math.round(toks.stop[1] * 60 / Math.pow(10, String(toks.stop[1]).length)) : 0)).slice(-2)+':'+(n+1))
                            //
                            //         this.tvSessionStartByDate[n] = Math.round(start * 3600)
                            //     }
                            // }

                            const glueSession = session => {
                                let r = []

                                for (let i = 0; i < 7; i++) {
                                    r = r.concat(session[i])
                                }

                                return r
                            }

                            const unglueSession = session => {
                                let r = []

                                for (let i = 0; i < 7; i++) {
                                    r.push(session.slice(i*24, (i+1)*24))
                                }

                                return r
                            }

                            let session = this.symbols[symbolName].sessions

                            session = glueSession(session)

                            session = unglueSession(session)

                            const makeSession = (start, stop) => {
                                let toks = {
                                    start: String(start).split('.'),
                                    stop: String(stop+1).split('.')
                                }

                                return ('0'+toks.start[0]).slice(-2)+('0'+(toks.start[1] !== undefined ? Math.round(toks.start[1] * 60 / Math.pow(10, String(toks.start[1]).length)) : 0)).slice(-2)+'-'+('0'+toks.stop[0]).slice(-2)+('0'+(toks.stop[1] !== undefined ? Math.round(toks.stop[1] * 60 / Math.pow(10, String(toks.stop[1]).length)) : 0)).slice(-2)
                            }

                            let sessions = []
                            for(let d=0; d<7; d++) {
                                let daySessions = []
                                let s = session[d],
                                    start = 0,
                                    stop = 0,
                                    started = false

                                for(let i=0; i<24; i++) {
                                    let cur = s[i]

                                    if (cur === 0 && !started) {
                                        start++
                                        stop++
                                        continue
                                    }

                                    if (cur === 0 && started) {
                                        daySessions.push(makeSession(start, stop))
                                        started = false
                                        start += 2
                                        stop += 2
                                        continue
                                    }

                                    if (cur === 1 && !started) {
                                        started = true
                                        this.tvSessionStartByDate[d] = Math.round(start * 3600)
                                        continue
                                    }

                                    if (cur === 1 && started) {
                                        stop++
                                        continue
                                    }

                                    if (!started) {
                                        started = true
                                        start += cur
                                        this.tvSessionStartByDate[d] = Math.round(start * 3600)
                                        continue
                                    }

                                    if (started) {
                                        stop += cur
                                        daySessions.push(makeSession(start, stop))
                                        started = false
                                    }
                                }

                                if (started) {
                                    daySessions.push(makeSession(start, stop))
                                }

                                if (daySessions.length) {
                                    sessions.push(daySessions.join(',')+':'+(d+1))
                                }
                            }

                            this.tvSession = sessions.join('|')

                            let conf = {
                                name: symbol.alias,
                                ticker: symbol.symbol,
                                description: symbol.alias,
                                type: symbol.market,
                                session: sessions.join('|'),
                                exchange: COMPANY_NAME,
                                timezone: 'Etc/UTC',
                                minmov: 1,
                                pricescale: Math.pow(10, symbol.precision),
                                has_intraday: true,
                                has_seconds: false,
                                has_daily: true,
                                has_weekly_and_monthly: false,
                                has_empty_bars: false,
                                force_session_rebuild: false,
                                has_no_volume: true,
                                volume_precision: 2,
                                data_status: 'streaming',
                                expired: false,
                                currency_code: symbol.base_currency
                            }

                            setTimeout(() => {
                                onSymbolResolvedCallback(conf)
                            }, 1)
                        },
                        getBars: (symbolInfo, resolution, periodParams, onHistoryCallback) => {
                            if (resolution === 'D' || resolution === '1D') {
                                resolution = '1440'
                            }

                            let offset = 0

                            if (!periodParams.firstDataRequest) {
                                offset = Math.round((Math.floor(Date.now()/1000) - periodParams.to)/(resolution*60))
                            }

                            let count = Math.round((Math.floor(Date.now()/1000) - periodParams.from)/(resolution*60)) - offset;

                            ((symbol) => {
                                RestService.get('/quotes/'+symbol+'/history', {
                                    frame: parseInt(resolution)*60,
                                    count,
                                    offset,
                                    group: this.symbols[symbolInfo.ticker].group
                                })
                                    .then(data => {
                                        if (symbol !== this.currentSym) {
                                            return
                                        }

                                        let ret = []

                                        if (!data || !data.length) {
                                            return onHistoryCallback(ret, {
                                                noData: true
                                            })
                                        }

                                        let lastDate = 0,
                                            lastShift = 0

                                        data.forEach(el => {
                                            let t = new Date(el[5]*1000),
                                                sessionStart = this.tvSessionStartByDate[t.getUTCDay()],
                                                sessionTimestamp = 86400 * Math.floor(el[5] / 86400) + sessionStart,
                                                candleStamp

                                            if (sessionStart) {
                                                if (resolution < 1440) {
                                                    if (sessionTimestamp > el[5] && sessionTimestamp < el[5] + resolution * 60) {
                                                        candleStamp = sessionTimestamp*1000

                                                        if (lastDate !== 86400 * Math.floor(el[5] / 86400)) {
                                                            lastDate = 86400 * Math.floor(el[5] / 86400)
                                                            lastShift = sessionTimestamp - el[5]
                                                        }
                                                    } else {
                                                        candleStamp = (el[5] + lastShift) * 1000
                                                    }
                                                } else {
                                                    candleStamp = 1000 * (86400 * Math.round(el[5] / 86400) + sessionStart)
                                                }
                                            } else {
                                                candleStamp = el[5]*1000
                                            }

                                            ret.push({
                                                open: el[0],
                                                high: el[1],
                                                low: el[2],
                                                close: el[3],
                                                volume: el[4],
                                                time: candleStamp
                                            })
                                        })

                                        this.lastCandle = {
                                            open: ret[ret.length - 1].open,
                                            high: ret[ret.length - 1].high,
                                            low: ret[ret.length - 1].low,
                                            close: ret[ret.length - 1].close,
                                            volume: ret[ret.length - 1].volume,
                                            time: ret[ret.length - 1].time
                                        }
                                        onHistoryCallback(ret)

                                        this.showOrderActive = true
                                        this.ready = true

                                        localStorage.setItem('tv-'+this.chart.id+'-interval', resolution)
                                    })
                            })(symbolInfo.ticker);
                        },
                        subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID) => {
                            if (resolution === 'D' || resolution === '1D') {
                                resolution = '1440'
                            }

                            if (this.subs[symbolInfo.ticker] === undefined) {
                                this.subs[symbolInfo.ticker] = {}
                            }

                            this.subs[symbolInfo.ticker][subscribeUID] = {
                                symbol: symbolInfo.ticker,
                                frame: resolution*60,
                                cb: onRealtimeCallback,
                                last: null
                            }
                        },
                        unsubscribeBars: (subscriberUID) => {
                            for(let sym in this.subs) {
                                for(let uid in this.subs[sym]) {
                                    if (subscriberUID === uid) {
                                        delete this.subs[sym][uid]
                                        return
                                    }
                                }
                            }
                        }
                    },
                    library_path: CDN_ENABLED ? 'https://p-cdn.in/'+BRAND+'/tradingview/' : '/tradingview/',
                    overrides: {
                        'paneProperties.background':this.lightTheme? '#fff' : this.themeColors['background-dark'],
                        'paneProperties.backgroundType': 'solid',
                        'paneProperties.vertGridProperties.color':  this.lightTheme ? '#9eb2da' : '#303642',
                        'paneProperties.horzGridProperties.color':  this.lightTheme ? '#9eb2da' : '#303642',
                        'scalesProperties.backgroundColor': this.themeColors['background-dark'],
                        'scalesProperties.lineColor': this.themeColors['line'],
                        'scalesProperties.textColor': this.themeColors['font'],
                        'editorFontsList': ['-apple-system', 'BlinkMacSystemFont', "Segoe UI", 'Roboto', "Helvetica Neue", 'Arial', "Noto Sans", 'sans-serif', "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"],
                        "mainSeriesProperties.candleStyle.wickUpColor": '#1df598',
                        "mainSeriesProperties.candleStyle.wickDownColor": '#ff163f',
                        "mainSeriesProperties.candleStyle.upColor": '#1df598',
                        "mainSeriesProperties.candleStyle.downColor": '#ff163f',
                    },
                    theme: this.lightTheme ? 'Light' : this.tradingviewTheme,
                    toolbar_bg: this.lightTheme ? '#ffffff' : this.themeColors['background-dark'],
                    loading_screen: {
                        backgroundColor: this.themeColors['background-dark']
                    },
                    custom_css_url: CDN_ENABLED ? 'https://p-cdn.in/'+BRAND+'/brands/'+BRAND+'/tradingview.css' : '/brands/'+BRAND+'/tradingview.css',
                    disabled_features: isMobile() ? ['widget_logo','left_toolbar', 'control_bar', 'timeframes_toolbar', 'legend_widget', 'header_compare', 'header_fullscreen_button'] : ['header_compare','widget_logo',`${['ironai','nfocapital'].includes(this.brand) ? 'header_screenshot' : ''}`],
                    time_frames: [
                        { text: "5y", resolution: "1W", description: "5 Years" },
                        { text: "1y", resolution: "1W", description: "1 Year" },
                        { text: "3m", resolution: "60", description: "3 Months" },
                        { text: "1m", resolution: "30", description: "1 Month" },
                        { text: "5d", resolution: "5", description: "5 Days" },
                        { text: "1d", resolution: "1", description: "1 Day" }
                    ]
                })

                const colorSettingInLocalStorage = JSON.parse(localStorage.getItem('tradingview.chartproperties'));

                if(colorSettingInLocalStorage?.paneProperties?.background){
                colorSettingInLocalStorage.paneProperties.background = this.lightTheme ? '#fff' : '#000'
                localStorage.setItem('tradingview.chartproperties',colorSettingInLocalStorage)
                }

            },
            checkSession,
            toggleOrderMenu() {
                this.orderMenu = !this.orderMenu
            }
        },
        computed: {
            ...mapGetters(['symbols', 'activeAccount', 'themeColors', 'tokens', 'gridLayout', 'gridCharts','lightTheme']),
            currentSym() {
                return this.chart.symbol || ''
            },
            quote() {
                if (!this.chart.symbol || !this.symbols[this.chart.symbol]) {
                    return {
                        symbol: '',
                        bid: null,
                        ask: null
                    }
                }

                return this.symbols[this.chart.symbol].quote
            },
            orders() {
                return this.activeAccount.orders
            }
        },
        watch: {
            themeColors(){
                for (let key in localStorage) {
                    if (key.startsWith('tv-') && !key.endsWith('-interval')) {
                        let jsonData = JSON.parse(localStorage.getItem(key));

                        jsonData.charts[0].chartProperties.paneProperties.background = this.lightTheme ? "#ffffff" : this.themeColors['background-dark'];
                        jsonData.charts[0].chartProperties.paneProperties.backgroundGradientEndColor = this.lightTheme ? "#ffffff" : this.themeColors['background-dark'];
                        jsonData.charts[0].chartProperties.paneProperties.backgroundGradientStartColor = this.lightTheme ? "#ffffff" : this.themeColors['background-dark'];
                        jsonData.charts[0].chartProperties.paneProperties.horzGridProperties.color = this.lightTheme ? '#9eb2da' : '#303642';
                        jsonData.charts[0].chartProperties.paneProperties.vertGridProperties.color = this.lightTheme ? '#9eb2da' : '#303642';
                        jsonData.charts[0].chartProperties.scalesProperties.lineColor = this.lightTheme ? '#fff' : this.themeColors['background-dark'];

                        let jsonString = JSON.stringify(jsonData);
                        localStorage.setItem(key, jsonString);
                        }
                    }
                this.drawChart()
            },
            quote: {
                deep: true,
                handler(q) {
                    if (!q || !this.ready) {
                        return
                    }

                    if (this.subs[q.symbol] !== undefined) {
                        for(let uid in this.subs[q.symbol]) {
                            let stamp = this.subs[q.symbol][uid].frame * 1000 * Math.floor(Date.now()/(1000 * this.subs[q.symbol][uid].frame))

                            let t = new Date(),
                                now = Math.floor(Date.now() / 1000),
                                frame = this.subs[q.symbol][uid].frame,
                                sessionStart = this.tvSessionStartByDate[t.getUTCDay()],
                                sessionTimestamp = 86400 * Math.floor(now / 86400) + sessionStart

                            if (sessionStart && sessionTimestamp < now && now < sessionTimestamp + frame) {
                                stamp = sessionTimestamp*1000
                            }

                            if (this.subs[q.symbol][uid].last === null) {
                                this.subs[q.symbol][uid].last = this.lastCandle
                                this.subs[q.symbol][uid].last = {
                                    time: stamp,
                                    open: this.lastCandle.open,
                                    high: Math.max(q.bid, this.lastCandle.high),
                                    low: Math.min(q.bid, this.lastCandle.low),
                                    close: q.bid
                                }

                                this.subs[q.symbol][uid].cb(this.subs[q.symbol][uid].last)
                            } else if (this.subs[q.symbol][uid].last.time !== stamp) {
                                this.subs[q.symbol][uid].last = {
                                    time: stamp,
                                    open: q.bid,
                                    high: q.bid,
                                    low: q.bid,
                                    close: q.bid
                                }

                                this.subs[q.symbol][uid].cb(this.subs[q.symbol][uid].last)
                            } else {
                                this.subs[q.symbol][uid].last.high = Math.max(this.subs[q.symbol][uid].last.high, q.bid)
                                this.subs[q.symbol][uid].last.low = Math.min(this.subs[q.symbol][uid].last.low, q.bid)
                                this.subs[q.symbol][uid].last.close = q.bid

                                this.subs[q.symbol][uid].cb(this.subs[q.symbol][uid].last)
                            }
                        }
                    }
                }
            },
            currentSym(val, old) {
                if (old !== val) {
                    this.drawChart()
                }
            },
            gridLayout() {
                this.orderMenu = !this.orderMenu
                setTimeout(() => {
                    this.orderMenu = !this.orderMenu
                }, 2)
            }
        }
    }
</script>

<style scoped>
    .chart {
        flex: 1 1 auto;
        display: flex;
        position: relative;
    }

    .chart-col {
        flex: 1 1 auto;
        flex-direction: column;
        align-items: stretch;
        display: flex;
        position: relative;
    }

    .show-order {
        position: absolute;
        background: var(--background-light);
        color: var(--white);
        border-radius: 3px;
        padding: 5px 10px;
        right: 72px;
        top: 50px;
        font-size: 1.1em;
        font-weight: 500;
        cursor: pointer;
    }

    .show-order.branded {
        background: var(--brand);
    }

    .chart-cont {
        flex: 1 1 auto;
        position: relative;
        overflow: hidden;
    }

    .cur-symbol {
        display: flex;
        align-items: center;
        position: absolute;
        top: 10px;
        left: 10px;
        cursor: pointer;
    }

    .cur-symbol img {
        height: 32px;
        width: 32px;
        margin-right: 10px;
    }

    .chart-type {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 10px;
        cursor: pointer;
        border-radius: 5px;
        padding: 2px 10px 2px 5px;
        border: 1px solid rgba(255,255,255,0.1);
        background: var(--background-dark);
    }

    .layout-small .chart-type, .layout-mid .chart-type {
        top: 10px;
        left: 150px;
    }

    .chart-type .item {
        display: flex;
        align-items: center;
    }

    .chart-type .item.active {
        color: var(--white)
    }

    .chart-type .item span {
        margin-left: 10px;
    }

    .col .name {
        font-size: 16px;
        color: var(--white);
        font-weight: 600;
        display: flex;
        align-items: center;
    }

    .col .cat {
        color: var(--font);
        font-weight: 600;
    }

    .scale {
        flex: 0 0 20px;
        height: 20px;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        background-color: var(--background-dark-mid);
        border-radius: 3px;
        overflow: hidden;
    }

    .scale .item {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: color ease-in-out 0.3s, background-color ease-in-out 0.3s;
    }

    .scale .item:hover, .scale .item.active {
        background-color: var(--background);
        color: var(--white);
    }

    .trend {
        position: absolute;
        left: 75px;
        top: 50%;
        margin-top: -60px;
    }

    .mobile .trend {
        left: 25px
    }

    .trend .item {
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
        margin-top: 0;
    }

    .layout-mid .trend .item {
        margin-top: -6px;
    }

    .layout-small .trend {
        display: none !important;
    }

    .layout-mid .trend {
        display: none !important;
    }

    .trend .item.green {
        background: var(--green-button);
        background: linear-gradient(0, rgba(43,171,64,1) 30%, rgba(55,64,84,0.5) 95%);
    }

    .trend .item.red {
        background: var(--red);
        background: linear-gradient(0, rgba(221,73,49,1) 30%, rgba(55,64,84,0.5) 95%);
    }

    .trend .label {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: var(--white);
        font-size: 1.2em;
    }

    .trend .label:first-child {
        margin-bottom: 3px;
    }

    .trend .label.top {
        position: absolute;
        left: -8px;
        width: 36px;
        display: flex;
        justify-content: center;
    }

    .trend .label.bottom {
        position: absolute;
        top: -30px;
        left: -8px;
        width: 36px;
        display: flex;
        justify-content: center;
    }

    .no-chart {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 2;
        background: var(--background-dark-mid);
        cursor: pointer;
        transition: color ease-in-out 0.3s;
    }

    .no-chart i {
        font-size: 8em;
        margin-bottom: 10px;
    }

    .no-chart span {
        font-size: 3em;
    }

    .no-chart:hover {
        color: var(--white-to-black)
    }

    .zoom {
        display: flex;
        position: absolute;
        bottom: 40px;
        left: 50%;
        align-items: center;
        justify-content: flex-end;
        margin-left: -80px;
    }

    .zoom i, .zoom svg {
        color: var(--white);
        font-size: 1.7em;
        cursor: pointer;
        width: 1.5em;
        height: 1.5em;
        background: var(--background-dark);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }

    .zoom i:last-child {
        margin-right: 0;
    }

    @media (max-height: 500px) {
        .trend {
            display: none !important;
        }

        .trend .item {
            margin-top: -5px;
        }

        .trend .label {
            display: none !important;
        }
    }

    @media (max-height: 300px) {
        .trend {
            display: none;
        }
    }

    @media screen and (orientation: portrait) {
        .chart {
            flex-direction: column;
        }

        .trend {
            top: 35%
        }
    }

    .mobile .show-order {
        display: none;
    }
</style>

<style>
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%
    }
</style>
