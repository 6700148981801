<template>
    <div @click="activeSelect = false">
        <div class="fullscreen-page-title">
            {{ tokens.fullScreen.withdrawal.toUpperCase() }}
        </div>
        <div class="row fs flex-start" :class="{hide: !user.enable_withdrawal}">
            <div class="form-row fs">
                <div class="form-title">
                    {{ tokens.fullScreenWithdrawal.requestNewWithdrawal }}
                </div>
            </div>
            <div class="col fs">

                <div class="form-row fs">
                    <label class="fs">{{ tokens.fullScreenWithdrawal.amount }}</label>
                    <input v-model="amount" class="form-control fs"/>
                    <!--                    <div class="tip">-->
                    <!--                        <span>{{ tokens.fullScreenWithdrawal.currentBalance }} {{ currencySign(activeAccount.currency)+activeAccount.balance.toFixed(activeAccount.precision) }}</span>-->
                    <!--                        <span class="all" v-on:click="amount = activeAccount.balance > 0 ? activeAccount.balance : 0">{{ tokens.fullScreenWithdrawal.withdrawAll }}</span>-->
                    <!--                    </div>-->
                </div>
                <div class="form-row fs">
                    <label class="fs">{{ tokens.fullScreenWithdrawal.withdrawalDetails }}</label>
                    <select class="form-control fs" v-model="method" :class="{activeSelect: activeSelect}"
                            @click.stop="activeSelect = !activeSelect">
                        <option v-for="(value, key) in withdrawalOptions" :key="key" :value="key">
                            {{ withdrawalOption(value) }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-row fs">
                <div class="form-title">
                    {{ tokens.fullScreenWithdrawal.withdrawalDetails.toUpperCase() }}
                </div>
            </div>
            <div class="col fs">
                <div class="form-row fs" v-bind:class="{hide: method !== 'btc' && method !== 'eth' && method !== 'usdt' && method !== 'matic' && method !== 'usdt_erc20'}">
                    <label class="fs">{{ tokens.fullScreenWithdrawal.address }}</label>
                    <input type="text" class="form-control fs" v-model="methods.btc.wallet">
                </div>
                <div v-if="bankWithdrawalFields.includes('account')" class="form-row fs" v-bind:class="{hide: method !== 'bank'}">
                    <label class="fs">{{ tokens.fullScreenWithdrawal.bankAccount }}</label>
                    <input type="text" class="form-control fs" v-model="methods.bank.account">
                </div>
                <div v-if="bankWithdrawalFields.includes('holder')" class="form-row fs" v-bind:class="{hide: method !== 'bank'}">
                    <label class="fs">{{ tokens.fullScreenWithdrawal.bankHolder }}</label>
                    <input type="text" class="form-control fs" v-model="methods.bank.holder">
                </div>
                <div v-if="bankWithdrawalFields.includes('iban')" class="form-row" v-bind:class="{hide: method !== 'bank'}">
                    <label class="fs">{{ brand === 'rileyfinance' ? 'BSB' : tokens.fullScreenWithdrawal.bankIban }}</label>
                    <input type="text" class="form-control fs" v-model="methods.bank.iban">
                </div>
                <div class="form-row fs" v-if="bankWithdrawalFields.includes('swift') && brand !== 'rileyfinance'" v-bind:class="{hide: method !== 'bank'}">
                    <label class="fs">{{ tokens.fullScreenWithdrawal.bankSwift }}</label>
                    <input type="text" class="form-control fs" v-model="methods.bank.swift">
                </div>
                <div v-if="bankWithdrawalFields.includes('sortCode')" class="form-row fs" v-bind:class="{hide: method !== 'bank'}">
                    <label class="fs">{{ tokens.fullScreenWithdrawal.sortCode }}</label>
                    <input type="text" class="form-control fs" v-model="methods.bank.sortCode"   v-mask="'##-##-##'">
                </div>
                <div v-if="bankWithdrawalFields.includes('ifscCode')" class="form-row fs" v-bind:class="{hide: method !== 'bank'}">
                    <label class="fs">{{ tokens.fullScreenWithdrawal.ifscCode }}</label>
                    <input type="text" class="form-control fs" v-model="methods.bank.ifscCode">
                </div>
                <div v-if="bankWithdrawalFields.includes('bankName')" class="form-row fs" v-bind:class="{hide: method !== 'bank'}">
                    <label class="fs">{{ tokens.fullScreenWithdrawal.bankName }}</label>
                    <input type="text" class="form-control fs" v-model="methods.bank.bankName">
                </div>
                <div v-if="bankWithdrawalFields.includes('bankBranch')" class="form-row fs" v-bind:class="{hide: method !== 'bank'}">
                    <label class="fs">{{ tokens.fullScreenWithdrawal.bankBranch }}</label>
                    <input type="text" class="form-control fs" v-model="methods.bank.bankBranch">
                </div>
                <div v-if="bankWithdrawalFields.includes('country')" class="form-row fs" v-bind:class="{hide: method !== 'bank'}">
                    <label class="fs">{{ 'Country' }}</label>
                    <input type="text" class="form-control fs" v-model="methods.bank.country">
                </div>
                <div v-if="bankWithdrawalFields.includes('city')" class="form-row fs" v-bind:class="{hide: method !== 'bank'}">
                    <label class="fs">{{ 'City' }}</label>
                    <input type="text" class="form-control fs" v-model="methods.bank.city">
                </div>
                <div v-if="bankWithdrawalFields.includes('street')" class="form-row fs" v-bind:class="{hide: method !== 'bank'}">
                    <label class="fs">{{ 'Street' }}</label>
                    <input type="text" class="form-control fs" v-model="methods.bank.street">
                </div>
                <div v-if="bankWithdrawalFields.includes('postcode')" class="form-row fs" v-bind:class="{hide: method !== 'bank'}">
                    <label class="fs">{{ 'Postcode' }}</label>
                    <input type="text" class="form-control fs" v-model="methods.bank.postcode">
                </div>
                <div class="form-row fs" v-bind:class="{hide: method !== 'card'}">
                    <label class="fs">{{ tokens.fullScreenWithdrawal.cardNumber }}</label>
                    <input type="text" class="form-control fs" v-model="methods.card.number">
                </div>
                <div class="form-row fs" v-bind:class="{hide: method !== 'card'}">
                    <label class="fs">{{ tokens.fullScreenWithdrawal.cardHolder }}</label>
                    <input type="text" class="form-control fs" v-model="methods.card.holder">
                </div>
            </div>
        </div>
        <div class="form-row pad fs-bottom" :class="{hide: !user.enable_withdrawal}">
            <button class="fs-normal" v-on:click="requestWithdrawal">{{
                    tokens.fullScreenWithdrawal.requestWithdrawal
                }}
            </button>
        </div>
        <div class="form-row fs">
            <div class="form-title">
                {{ tokens.fullScreenWithdrawal.withdrawalRequests }}
            </div>
        </div>
        <div class="table-cont">
            <table class="cabinet fs">
                <thead>
                <tr>
                    <th class="nomobile fs" v-if="!hideWithdrawalTime">{{ tokens.fullScreenWithdrawal.time.toUpperCase() }}</th>
                    <th class="fs">{{ tokens.fullScreenWithdrawal.amount.toUpperCase() }}</th>
                    <th class="fs">{{ tokens.fullScreenWithdrawal.currency.toUpperCase() }}</th>
                    <th class="fs" v-if="!disableInfoTable">{{ tokens.fullScreenWithdrawal.info.toUpperCase() }}</th>
                    <th class="fs">{{ tokens.fullScreenWithdrawal.status.toUpperCase() }}</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="withdrawal in withdrawals.slice(0,10)"
                    :key="withdrawal.id"
                >
                    <td class="nomobile" v-if="!hideWithdrawalTime">{{ brand === 'uccglobal' ? new Date(withdrawal.time_created * 1000).toLocaleString(undefined, { year: 'numeric' }) : new Date(withdrawal.time_created * 1000).toLocaleString() }}</td>
                    <td class="fs">{{
                            currencySign(withdrawal.currency) + Math.abs(withdrawal.amount).toFixed(activeAccount.precision)
                        }}
                    </td>
                    <td colspan="fs">{{ withdrawal.currency }}</td>
                    <td class="fs" v-if="!disableInfoTable">{{ withdrawal.comment }}</td>
                    <td class="fs">
                        {{ tokens.statuses[withdrawal.status] }}
                        <i v-if="withdrawal.status === 0 && !hideWithdrawal" @click="confirmCancelWithdrawal(withdrawal.id)"
                           class="material-icons cancel-withdrawal" title="Cancel withdrawal request">cancel</i>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {currencySign} from "@/common/helpers";
import {WITHDRAWAL_REQUEST} from "@/store/actions.type";
import {NOTIFICATION_ERROR, MODAL, MODAL_WITHDRAWAL_CANCEL} from "@/store/mutations.type";
import {
    BRAND,
    CLOSE_WITHDRAWAL,
    DISABLE_INFO_TABLE,
    MINIMUM_WITHDRAWAL,
    WITHDRAWAL_OPTIONS,
    DEFAULT_WITHDRAWAL_CURRENCY,
    BANK_WITHDRAWAL_FIELDS,
    REQUIRED_WITHDRAWAL, HIDE_WITHDRAWAL_TIME
} from "@/common/config";
import {mask} from 'vue-the-mask'

export default {
    name: "Withdrawal",
    directives: {mask},
    data() {
        return {
            amount: '',
            hideWithdrawal: CLOSE_WITHDRAWAL,
            method: WITHDRAWAL_OPTIONS.bank ? 'bank' : 'btc',
            disableInfoTable : DISABLE_INFO_TABLE,
            bankWithdrawalFields: BANK_WITHDRAWAL_FIELDS,
            activeSelect: false,
            hideWithdrawalTime: HIDE_WITHDRAWAL_TIME,
            methods: {
                btc: {
                    wallet: ''
                },
                bank: {
                    account: '',
                    holder: '',
                    iban: '',
                    swift: '',
                    sortCode: '',
                    ifscCode: '',
                    bankName: '',
                    bankBranch: '',
                    country: '',
                    city: '',
                    street: '',
                    postcode: '',
                },
                card: {
                    number: '',
                    holder: ''
                }
            },
            defaultWithdrawalCurrency: DEFAULT_WITHDRAWAL_CURRENCY,
            withdrawalOptions: WITHDRAWAL_OPTIONS,
            brand: BRAND
        }
    },
    methods: {
        confirmCancelWithdrawal(id) {
            this.$store.commit(MODAL_WITHDRAWAL_CANCEL, id)
            this.$store.commit(MODAL, {
                withdrawalCancel: true
            })
        },
        withdrawalOption(value){
          if (BRAND === "cryptossaylor") {
             switch (value) {
                 case 'bank': return 'Bank Transfer'
                 case 'card': return 'Credit Card'
                 default: return this.tokens.fullScreenWithdrawal[value]
             }
          }

          //crutch for adlerch, novixgrade
          if (['adlerch','novixgrade'].includes(this.brand)) {
             switch (value) {
                 case 'bank': return 'Wire Transfer'
                 case 'bitcoin': return 'BTC'
                 case 'ethereum': return 'ETH'
                 case 'usdt': return 'USDT TRC-20'
                 case 'matic': return 'Other'
                 default: return this.tokens.fullScreenWithdrawal[value]
             }
          }
          return this.tokens.fullScreenWithdrawal[value]
        },
        requestWithdrawal() {
            if (BRAND === "stbmarkets" && Object.keys(this.orders).length) {
                this.$store.commit(NOTIFICATION_ERROR, 'CLOSE_OPEN_ORDERS')
                return
            }

            let amount = String(this.amount)

            amount = amount.includes(',') ? amount.replace(/,/, '.') : amount
            amount = parseFloat(amount) || 0

            if (amount <= 0 || amount > this.activeAccount.balance) {
                this.$store.commit(NOTIFICATION_ERROR, 'The amount is incorrect')
                return
            }

            for(let n in this.withdrawals) {
                if (this.withdrawals[n].status === 0) {
                    this.$store.commit(NOTIFICATION_ERROR, 'LOCAL_ONE_PENDING_WITHDRAWAL')
                    return
                }
            }

            if (isNaN(parseFloat(amount)) || parseFloat(amount) < MINIMUM_WITHDRAWAL) {
                this.$store.commit(NOTIFICATION_ERROR, 'Minimum withdrawal amount is $' + MINIMUM_WITHDRAWAL)
                return
            }

            if (this.method === 'btc') {
                if (amount * this.symbols['BTCUSD'].quote.bid < MINIMUM_WITHDRAWAL) {
                    this.$store.commit(NOTIFICATION_ERROR, 'Minimum withdrawal amount is $' + MINIMUM_WITHDRAWAL)
                    return
                }
            } else if (this.method === 'bank' || this.method === 'card') {
                if (amount < MINIMUM_WITHDRAWAL) {
                    this.$store.commit(NOTIFICATION_ERROR, 'Minimum withdrawal amount is $' + MINIMUM_WITHDRAWAL)
                    return
                }
            }

            if(this.method === 'bank' && this.bankWithdrawalFields.includes('sortCode')){
               if(this.methods.bank.sortCode.length !== 8){
                this.$store.commit(NOTIFICATION_ERROR, 'Please complete Sort Code field')
                return
               }
            }

            if(REQUIRED_WITHDRAWAL){
                if(this.method === 'card'){
                    if(!this.methods.card.number.trim() || !this.methods.card.holder.trim()){
                        this.$store.commit(NOTIFICATION_ERROR, 'Please fill in all fields' )
                        return
                    }
                }else if(this.method === 'bank'){
                    for (let i = 0; i < BANK_WITHDRAWAL_FIELDS.length; i++){
                        if(!this.methods.bank[BANK_WITHDRAWAL_FIELDS[i]].trim()){
                        this.$store.commit(NOTIFICATION_ERROR, 'Please fill in all fields' )
                            return
                        }
                    }
                }else{
                    if(!this.methods.btc.wallet.trim()){
                        this.$store.commit(NOTIFICATION_ERROR, 'Please fill in all fields' )
                        return
                    }
                }
            }

            let details = ''
            switch (this.method) {
                case 'btc':
                    details = this.methods.btc.wallet
                    break
                case 'bank':
                    details =
                        `Account: ${this.methods.bank.account}\nAccount holder: ${this.methods.bank.holder}${this.methods.bank.iban ? `\nIBAN: ` + this.methods.bank.iban : ''}${this.methods.bank.swift ? '\nSWIFT: ' + this.methods.bank.swift : ''}${this.methods.bank.sortCode ? '\nSort Code: ' + this.methods.bank.sortCode : ''}${this.methods.bank.ifscCode ? '\nIFSC Code: ' + this.methods.bank.ifscCode : ''}${this.methods.bank.bankName ? '\nBank Name: ' + this.methods.bank.bankName : ''}${this.methods.bank.bankBranch ? '\nBank Branch: ' + this.methods.bank.bankBranch : ''}${this.methods.bank.country ? '\nCountry: ' + this.methods.bank.country : ''}${this.methods.bank.city ? '\nCity: ' + this.methods.bank.city : ''}${this.methods.bank.street ? '\nStreet: ' + this.methods.bank.street : ''}${this.methods.bank.postcode ? '\nPostcode: ' + this.methods.bank.postcode : ''}`
                    break
                case 'card':
                    details =
                        `Card: ${this.methods.card.number}
                            Card holder: ${this.methods.card.holder}`
                    break
                case 'eth':
                    details = `ETH: ${this.methods.btc.wallet}`
                    break
                case 'usdt':
                    details = `USDT: ${this.methods.btc.wallet}`
                    break
                case 'usdt_erc20':
                    details = `USDT ERC-20: ${this.methods.btc.wallet}`
                    break
                case 'matic':
                    details = `MATIC: ${this.methods.btc.wallet}`
                    break
            }

            this.$store.dispatch(WITHDRAWAL_REQUEST, {
                wallet: details,
                amount: amount,
                account_id: this.activeAccount.id,
                product: this.activeAccount.product,
                currency: this.defaultWithdrawalCurrency ? this.defaultWithdrawalCurrency.replaceAll('\'', '') :  this.activeAccount.currency
            })

            this.method = 'bank'
            this.methods.btc.wallet = ''
            this.methods.bank.account = ''
            this.methods.bank.holder = ''
            this.methods.bank.iban = ''
            this.methods.bank.swift = ''
            this.methods.bank.sortCode = ''
            this.methods.bank.ifscCode = ''
            this.methods.bank.bankName = ''
            this.methods.bank.bankBranch = ''
            this.methods.card.number = ''
            this.methods.card.holder = ''
            this.amount = ''
        },
        currencySign
    },
    computed: {
        ...mapGetters(['withdrawals', 'orders', 'activeAccount', 'realAccount', 'symbols', 'user', 'tokens']),
        balances() {
            let b = {
                balance: 0,
                orders: 0,
                profit: 0,
                credit: 0,
                margin: 0,
                // equity: 0,
                margin_level_apprx: 0,
                free_margin_apprx: 0,
                equity_apprx: 0
            }

            if (!this.activeAccount.id) {
                return b
            }

            b.balance = this.activeAccount.balance
            b.credit = this.activeAccount.credit

            let marginBySymbol = {}

            for(let n in this.activeAccount.orders) {
                let o = this.activeAccount.orders[n]

                o.easy_amount = o.easy_amount || o.margin

                b.orders += o.easy_amount

                if (marginBySymbol[o.symbol] === undefined) {
                    marginBySymbol[o.symbol] = 0
                }

                marginBySymbol[o.symbol] += o.type % 2 ? -o.margin : o.margin

                if (!this.symbols[o.symbol]) {
                    continue
                }

                b.profit += o.type < 2 ? (o.point * (o.type ? o.price_open - this.symbols[o.symbol].quote.ask : this.symbols[o.symbol].quote.bid - o.price_open) * Math.pow(10, this.symbols[o.symbol].precision) + o.swap + o.commission) : 0
            }

            for(let n in marginBySymbol) {
                b.margin += Math.abs(marginBySymbol[n])
            }

            b.equity = b.balance + b.credit + b.profit
            b.margin_level_apprx = this.activeAccount.balance + this.activeAccount.credit + b.profit !== 0 && b.margin ? (100 * (this.activeAccount.balance + this.activeAccount.credit + b.profit) / b.margin).toFixed(2) : ''
            b.free_margin_apprx = this.activeAccount.balance + this.activeAccount.credit + b.profit - b.margin
            b.equity_apprx =  b.equity

            return b
        }
    }

}
</script>

<style scoped>
.row.fs {
    grid-template-columns: 1fr;
}

.col {
    flex: 1 1 40%
}

.col.fs {
    margin-bottom: .5vw;
}

input, select {
    width: 100%;
}

.tip {
    font-size: 1.1em;
    font-weight: 400;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin-top: 5px;
}

.all {
    margin-left: 20px;
    color: var(--white);
    border-bottom: 1px dashed var(--white);
    cursor: pointer;
    white-space: nowrap;
}

.pad {
    margin-bottom: 50px;
}

.cancel-withdrawal {
    vertical-align: middle;
    cursor: pointer;
}

@media (min-width: 1000px) {
    .fs-bottom {
        margin-bottom: 2vw;
    }
}

@media (max-width: 1000px) {
    .col.fs {
        margin-bottom: 20px;
    }
}

</style>
